import React, { useState } from 'react'
import { Link, Routes, Route, BrowserRouter } from 'react-router-dom'
import './css/header.css'

import { Text } from './components/typography'
import Logo from './components/logo/SENLogo.png'
import { UserContext, IUser } from './context/user-context'
import { CreationToolSetup } from './views/creation-tool/CreationToolSetup'
import { PathwayCategories } from './views/pathway-categories'
import { Home } from './views/home'
import { PathwayOverview } from './views/pathway-overview'
import { DashBoard } from './views/admin-dashboard'
import { PathwayDisplaySetup } from './views/pathway-display/PathwayDisplaySetup'
import { Login } from './views/login'
import { Logout } from './views/logout'
import { UserPortal } from './views/user-portal'
import { Acknowledgements } from './views/acknowledgements'
import { About } from './views/about'
import { ContactUs } from './views/contact-us'
import { SupportingDocuments } from './views/supporting-documents'
import { Footer } from './components/footer'

export const App: React.FC = () => {
  const [user, setUser] = useState<IUser | null>(null)
  const userToken = localStorage.getItem('userToken') ?? ''

  return (
    <div className="page-container">
      <div className="content-wrap">
        <BrowserRouter>
          <div className="App" style={{ minHeight: '90vh' }}>
            <nav style={{ marginBottom: '1vh' }}>
              <ul>
                <li>
                  <Link to="/" style={{ padding: '0px' }}>
                    <img src={Logo} className="logoNav" alt="Logo" />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <Text color="#fafafa">Home</Text>
                  </Link>
                </li>
                <li>
                  <Link to="/pathway-categories">
                    <Text color="#fafafa">Finding Pathways</Text>
                  </Link>
                </li>
                <li>
                  <Link to="/supporting-documents">
                    <Text color="#fafafa">Supporting Documents</Text>
                  </Link>
                </li>
                <li>
                  <Link to="/about">
                    <Text color="#fafafa">About</Text>
                  </Link>
                </li>
                <li>
                  <Link to="/acknowledgements">
                    <Text color="#fafafa">Acknowledgements</Text>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <Text color="#fafafa">Contact Us</Text>
                  </Link>
                </li>
                {userToken !== '' && (
                  <li>
                    <Link to="/admin/dashboard">
                      <Text color="#fafafa">Admin Dashboard</Text>
                    </Link>
                  </li>
                )}
                {userToken !== '' && (
                  <li>
                    <Link to="/admin/pathway-overview">
                      <Text color="#fafafa">Pathway Management</Text>
                    </Link>
                  </li>
                )}
                {userToken !== '' && (
                  <li>
                    <Link to="/admin/logout">
                      <Text color="#fafafa">Logout</Text>
                    </Link>
                  </li>
                )}
              </ul>
            </nav>

            <div className="main" style={{ minHeight: '90vh' }}>
              <UserContext.Provider value={{ user, setUser }}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/pathway-categories" element={<PathwayCategories />} />
                  <Route path="/pathway-display/:id" element={<PathwayDisplaySetup />} />
                  <Route path="/admin/login" element={<Login />} />
                  <Route path="/admin/logout" element={<Logout />} />
                  <Route path="/admin/user-portal" element={<UserPortal />} />
                  <Route path="/admin/dashboard" element={<DashBoard />} />
                  <Route path="/admin/pathway-overview" element={<PathwayOverview />} />
                  <Route path="/admin/creation-tool/:id" element={<CreationToolSetup />} />
                  <Route path="/acknowledgements" element={<Acknowledgements />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<ContactUs />} />
                  <Route path="/supporting-documents" element={<SupportingDocuments />} />
                  <Route path="*" element={<Home />} />
                </Routes>
              </UserContext.Provider>
            </div>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  )
}
