/**
 * HTMLRenderer component used to take in content with HTML syntax and returns content
 * stylized appropriately.
 */
import React from 'react'
import './HTMLRenderer.css'

export const HTMLRenderer: React.FC<IProps> = ({ content }) => (
  <div className="html-renderer" dangerouslySetInnerHTML={{ __html: content }} />
)

interface IProps {
  content: string // content to render as html
}
