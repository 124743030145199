import { useEffect, useState } from 'react'
import { MDBContainer, MDBFooter } from 'mdbreact'
import { SmallText } from '../typography'
import { wrapPromise } from '../../utils/promise'
import { getLastUpdated } from '../../api/pathways/public'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0.5rem;
`

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Footer = () => {
  const [copyrightMessage, setCopyrightMessage] = useState<string>('© SEN Decisions 2023')
  // Get the year the pathways were updated to use as the copyright year
  useEffect(() => {
    void (async () => {
      const [res, err] = await wrapPromise(getLastUpdated())
      // if an error occurs return
      if (err !== null) return

      const date = new Date(res) // Convert the string to a date object
      setCopyrightMessage(`© SEN Decisions ${date.getFullYear()}`) // Set copyright message
    })()
  })
  return (
    <MDBFooter color="blue" className="font-small" style={{ height: '4vh', marginTop: 'auto' }}>
      <Container>
        <MDBContainer fluid>
          <SmallText color="white">{copyrightMessage}</SmallText>
        </MDBContainer>
      </Container>
    </MDBFooter>
  )
}
