import React from 'react'
import { Heading, SmallText, Text, Title } from '../../components/typography'
import styled from 'styled-components'

const BodyContainer = styled.div`
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  border: 0.1rem solid deepskyblue;
  height: 100%;

  @media (orientation: portrait) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

export const SupportingDocuments: React.FC = () => {
  return (
    <div>
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem', textAlign: 'center' }}
      >
        <Title>Supporting Documents</Title>
        <SmallText color="grey">Documents That May Be Useful to View Alongside the Pathways</SmallText>
      </div>
      <BodyContainer style={{ marginBottom: '1.5rem' }}>
        <Heading style={{ marginTop: '1.3rem' }}>Useful Documents</Heading>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Acronyms.docx">Acronyms</a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Case law index.docx">Case Law Index</a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/EHC Assessment request evidence.docx">
            EHC Assessment Request Evidence
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Legal basis.docx">Legal Basis</a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Support organisations.docx">Support Organisations</a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Timelines.docx">Timelines</a>
        </Text>
        <Heading style={{ marginTop: '1.3rem' }}>Model Letters</Heading>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Adaptation of letters.docx">
            Adaptation of Letters
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 1 Request needs assessment.docx">
            Letter 1: Request Needs Assessment
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 2 Request early re-assessment.docx">
            Letter 2: Request Early Re-Assessment
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 3 Changes in draft EHC plan.docx">
            Letter 3: Changes in Draft EHC Plan
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 4 Objecting to amendments.docx">
            Letter 4: Objecting to Ammendments
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 6 Provision unavailable.docx">
            Letter 6: Provision Unavailable
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 7 Assessment advice failure.docx">
            Letter 7: Assessment Advice Failure
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 8 Annual review or outcome overdue.docx">
            Letter 8: Annual Review or Outcome Overdue
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 9 Assessment request no response.docx">
            Letter 9: Assessment Request No Response
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 10 Transition review delays.docx">
            Letter 10: Transistion Review Delays
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 11 Examination support.docx">
            Letter 11: Examination Support
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 12 Naming the school.docx">
            Letter 12: Naming the School
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 13 Draft or Final plan delay.docx">
            Letter 13: Draft or Final Plan Delay
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 14 LA fails to implement actions.docx">
            Letter 14: LA Fails to Implement Actions
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 15 Information request to LA.docx">
            Letter 15: Information Request to LA
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 16 School information for appeal.docx">
            Letter 16: School Information for Appeal
          </a>
        </Text>
        <Text style={{ fontSize: '1.3rem', marginBottom: '1vh' }}>
          <a href="https://www.sendecisions.org.uk/resources/docs/Model Letters/Letter 18 EOTAS home school plan amendment.docx">
            Letter 18: EOTAS Home School Plan Amendment
          </a>
        </Text>
        <SmallText style={{ fontSize: '0.8rem' }} color="grey">
          Please Note Letters 5 and 17 Have Been Removed
        </SmallText>
      </BodyContainer>
    </div>
  )
}
