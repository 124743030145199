/**
 * Defines the typography for a subtitle
 */
import styled from 'styled-components'

import { TypographyBase } from './TypographyBase'

export const SubTitle = styled(TypographyBase)`
  font-size: 1.875rem;
  font-weight: ${props => (props.bold !== undefined ? 700 : 600)};
  color: ${props => (props.color !== undefined ? props.color : '#00bfff')};
`
