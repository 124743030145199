/**
 * Defines the typography for text used in a dropdown menus
 */
import styled from 'styled-components'

import { SmallText } from './SmallText'

export const DropdownText = styled(SmallText)`
  font-size: 0.75rem;
`
