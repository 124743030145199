import { DropdownInput, IDropdownOption } from './DropdownInput'
import { RichTextInput } from './RichTextInput'
import { TextInput } from './TextInput'
import { TextAreaInput } from './TextAreaInput'

export type { IDropdownOption }

export const Input = {
  Dropdown: DropdownInput,
  RichText: RichTextInput,
  Text: TextInput,
  TextArea: TextAreaInput
}
