import React from 'react'
import { AiOutlineInfoCircle, AiOutlineQuestionCircle } from 'react-icons/ai'
import styled from 'styled-components'

import { Button } from '../../../../components/button'
import { Text } from '../../../../components/typography'

import { useDnDNode } from '../../hooks'

const Container = styled.main`
  position: fixed;
  z-index: 10;
  justify-content: space-between;
  align-items: center;

  display: flex;

  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  padding: 0 2.75rem;

  pointer-events: none;
`

const EditToolsContainer = styled.div`
  display: flex;

  pointer-events: auto;
`

const HeaderContainer = styled.div`
  position: fixed;
  left: 50%;
  align-items: baseline;

  display: flex;

  transform: translate(-50%);

  pointer-events: auto;
`

export const Toolbar: React.FC<IProps> = ({ pathwayName, categoryName }) => {
  const { onDragNode } = useDnDNode(null)

  return (
    <Container>
      <EditToolsContainer>
        <Button.Icon size="3rem" onDragStart={evt => onDragNode(evt, 'information-node')} draggable>
          <AiOutlineInfoCircle size="1.75rem" />
        </Button.Icon>
        <Button.Icon size="3rem" onDragStart={evt => onDragNode(evt, 'decision-node')} draggable>
          <AiOutlineQuestionCircle size="1.75rem" />
        </Button.Icon>
      </EditToolsContainer>

      <HeaderContainer>
        <Text style={{ fontSize: '1.5vw' }} color="#7a7a7a">
          {categoryName} /&nbsp;
        </Text>
        <Text style={{ fontSize: '1.5vw' }} bold color="deepskyblue">
          {pathwayName}
        </Text>
      </HeaderContainer>
    </Container>
  )
}

interface IProps {
  pathwayName: string
  categoryName: string
}
