import { Elements } from 'react-flow-renderer/nocss'
import {
  ICreatePathwayResponse,
  IReadPathwayResponse,
  sanitizePathData,
  IUpdatePathwayResponse,
  IPublishPathwayResponse,
  IReadPathwayAttributes
} from '../shared'
import { apiInstance } from '../../axios'

/**
 * Creates a new empty pathway
 * @param name The name of the pathway
 * @param description The description of the pathway
 * @param categoryID The category's ID of the pathway
 * @returns The response, either successful or not, from the API
 */
export const createPathway = async (
  name: string,
  description: string,
  categoryID: number,
  accessToken: string
): Promise<ICreatePathwayResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(
        '/pathways/private/new',
        {
          Name: name,
          Description: description,
          Data: [],
          catID: categoryID
        },
        { headers: { authorization: `Bearer ${accessToken}` } }
      )
      .then(res => resolve(res.data))
      .catch(reject)
  })
}

/**
 * Creates a new empty pathway
 * @param name The name of the pathway
 * @param description The description of the pathway
 * @param categoryID The category's ID of the pathway
 * @returns The response, either successful or not, from the API
 */
export const savePathwayAs = async (
  name: string,
  description: string,
  data: Elements<CustomNodeData>,
  categoryID: number,
  accessToken: string
): Promise<ICreatePathwayResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(
        '/pathways/private/new',
        {
          Name: name,
          Description: description,
          Data: data,
          catID: categoryID
        },
        { headers: { authorization: `Bearer ${accessToken}` } }
      )
      .then(res => {
        resolve(res.data)
      })
      .catch(reject)
  })
}

/**
 * Fetches a specific pathway
 * @param id The ID of the pathway
 * @returns The response, either successful or not, from the API
 */
export const readPathway = async (id: number, accessToken: string): Promise<IReadPathwayResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance // we use the api instance
      .get<IReadPathwayResponse>(`/pathways/private/get/${id}`, { headers: { authorization: `Bearer ${accessToken}` } }) // get the response
      .then(res => {
        // if fufilled
        res.data.pathData = sanitizePathData(res.data.pathData) // santize the pathway
        resolve(res.data) // returns response data
      })
      .catch(reject) // returns error
  })
}

export const listPathways = async (accessToken: string): Promise<IReadPathwayAttributes[]> => {
  return await new Promise((resolve, reject) => {
    apiInstance // use api
      .get<IReadPathwayAttributes[]>('/pathways/private/list', { headers: { authorization: `Bearer ${accessToken}` } }) // get list of pathways
      .then(res => resolve(res.data)) // if fufilled then return response data
      .catch(reject) // otherwise return error
  })
}

/**
 * Updates an existing pathway
 * @param id The ID of the pathway to update
 * @param name The updated name of the pathway
 * @param description The updated description of the pathway
 * @param data The updated data stored in the pathway
 * @param categoryID The updated category's ID of the pathway
 * @returns The response, either successful or not, from the API
 */
export const updatePathway = async (
  id: number,
  name: string,
  description: string,
  data: Elements<CustomNodeData>,
  categoryID: number,
  accessToken: string
): Promise<IUpdatePathwayResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(
        // this is where the updating all happens
        '/pathways/private/update',
        {
          ID: id,
          Name: name,
          Description: description,
          Data: sanitizePathData(data),
          catID: categoryID
        },
        { headers: { authorization: `Bearer ${accessToken}` } }
      )
      .then(res => {
        resolve(res.data)
      })
      .catch(reject) // otherwise throws err
  })
}

/**
 * Publishes an existing pathway
 * @param id The ID of the pathway to publish
 * @returns The response, either successful or not, from the API
 */
export const publishPathway = async (
  id: number,
  name: string,
  description: string,
  data: Elements<CustomNodeData>,
  categoryID: number,
  accessToken: string
): Promise<IPublishPathwayResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(
        // this is where the updating all happens
        '/pathways/private/publish',
        {
          ID: id,
          Name: name,
          Description: description,
          Data: sanitizePathData(data),
          catID: categoryID
        },
        { headers: { authorization: `Bearer ${accessToken}` } }
      )
      .then(res => resolve(res.data)) // resolves the response data if fufilled
      .catch(reject) // otherwise throws err
  })
}

/**
 * Unpublishes an existing pathway
 * @param id The ID of the pathway to publish
 * @returns The response, either successful or not, from the API
 */
export const unpublishPathway = async (id: number, accessToken: string): Promise<ICreatePathwayResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post('/pathways/private/unpublish', { ID: id }, { headers: { authorization: `Bearer ${accessToken}` } })
      .then(res => resolve(res.data)) // resolves the response data if fufilled
      .catch(reject) // otherwise throws err
  })
}

/**
 * Deletes a pathway given its ID
 * @param id Pathway ID
 * @param accessToken Required access token
 * @returns The response, either successful or not, from the API
 */
export const deletePathway = async (id: number, accessToken: string): Promise<ICreatePathwayResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(`/pathways/private/delete`, { ID: id }, { headers: { authorization: `Bearer ${accessToken}` } }) // sends delete command
      .then(res => resolve(res.data)) // fufills response
      .catch(reject) // otherwise err
  })
}
