import React, { useState, useEffect } from 'react'
import { Title, SmallText, SubTitle } from '../../components/typography'
import { CategoryAddPopup } from './category-add'
import { PathwayAddPopup } from './pathway-add'
import { CategoryDeletePopup } from './category-delete'
import { PathwayDeletePopup } from './pathway-delete'
import { wrapPromise } from '../../utils/promise'
import { ExportedPathway } from '../../utils/exportedPathway'
// function used to interact with the backend to fetch a list of the categories
import { listPathways, readPathway } from '../../api/pathways/private'
// function used to interact with the backend to fetch a list of the private pathways
import { listCategories } from '../../api/categories'
// needed to use the groupby function
import _, { reject } from 'lodash'
// needed to be able to close pathways for each category section
import { Collapsible } from '../../components/collapsible'
// you need these styles for React Flow to work properly
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import { PathwayRestorePopup } from './pathway-restore'
import JSZip from 'jszip'

const PathTable = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 8px;
  }

  table td {
    padding: 12px 15px;
    background-color: rgb(219, 219, 219);
    text-align: center;
  }

  table tr:hover td {
    background-color: rgb(255, 255, 255);
  }

  table th {
    padding-top: 12px;
    padding-bottom: 15px;
    text-align: center;
    background-color: rgb(0, 0, 0);
    color: white;
  }
`

const OptionButtonRow1 = styled.input`
  width: 24.5%;
  line-height: 2px;
  border-radius: 8px;
  overflow: hidden;
  height: 40px;
  margin-left: 0.25%;
  margin-right: 0.25%;
`

const OptionButtonRow2 = styled.input`
  width: 49.5%;
  line-height: 2px;
  border-radius: 8px;
  overflow: hidden;
  height: 40px;
  margin-left: 0.25%;
  margin-right: 0.25%;
`

const LinkButton = styled.button`
  :hover {
    color: deepskyblue;
  }
`

export const PathwayOverview: React.FC = () => {
  const userToken = localStorage.getItem('userToken') ?? ''
  const pathwaysList = [{ pathID: 0, pathName: '', pathDescription: '', catID: 0 }]
  const categoriesList = [{ catID: 0, catName: '' }]
  const [stateOptionsPath, setStateValues] = useState(pathwaysList)
  const [stateOptionsCat, setStateValues2] = useState(categoriesList)
  const [stateOptionsCat2, setStateValues3] = useState(categoriesList)
  const [isOpenCatAdd, setIsOpenCatAdd] = useState(false)
  const [isOpenPathAdd, setIsOpenPathAdd] = useState(false)
  const [isOpenPathRestore, setIsOpenPathRestore] = useState(false)
  const [isOpenCatDelete, setIsOpenCatDelete] = useState(false)
  const [isOpenPathDelete, setIsOpenPathDelete] = useState(false)
  // function sets a boolean value to keep track of the open state of the popups
  const toggleCategoryAdd = (): void => {
    setIsOpenCatAdd(!isOpenCatAdd)
  }
  const togglePathwayAdd = (): void => {
    setIsOpenPathAdd(!isOpenPathAdd)
  }
  const togglePathwayRestore = (): void => {
    setIsOpenPathRestore(!isOpenPathRestore)
  }
  const toggleCategoryDelete = (): void => {
    setIsOpenCatDelete(!isOpenCatDelete)
  }
  const togglePathwayDelete = (): void => {
    setIsOpenPathDelete(!isOpenPathDelete)
  }

  const downloadAllPathways = async (): Promise<void> => {
    toast.info('Preparing Pathways For Download')

    let downloadedCount = 0
    const zip = JSZip()

    // Get current date and convert to a string
    const today = new Date()
    const dateString = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(
      2,
      '0'
    )}-${today.getFullYear()}`

    for (let i = 0; i < stateOptionsPath.length; i++) {
      const [res, err] = await wrapPromise(readPathway(stateOptionsPath[i].pathID, userToken)) // read pathway, return response
      if (err !== null) {
        toast.error(`Unable To Get Pathway With ID ${stateOptionsPath[i].pathID}`)
        continue
      }

      // Define JSON object to be exported
      const pathwayExport: ExportedPathway = {
        ID: res.pathID,
        Name: res.pathName,
        Description: res.pathDescription,
        Data: res.pathData,
        catID: res.catID
      }

      // Data to be written to a file
      const fileData = JSON.stringify(pathwayExport)
      const fileName = `${res.pathID}-${res.pathName.replaceAll('/', '_').replaceAll('\\', '_')}-${dateString}.json`

      // Add file to ZIP
      zip.file(fileName, fileData)
      downloadedCount++
    }

    // Generate and download ZIP file
    void zip.generateAsync({ type: 'blob' }).then(function (blob) {
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.download = `exported_pathways_${dateString}.zip`
      link.href = url
      link.click()
    })

    toast.success(`${downloadedCount} Pathways Successfully Downloaded`)
  }

  /**
   * Uses the id parameter to find and return the category name as a string
   * @param id type number that is used to find the associated category name with that id
   * @return type string, category name if found, else wrong
   */
  const catName = (id: number): string => {
    let i = 0
    while (i !== stateOptionsCat.length) {
      // compares the id associated with the element in the array with the id submitted
      if (stateOptionsCat[i].catID === id) {
        return stateOptionsCat[i].catName
      }
      i++
    }
    return 'wrong'
  }
  // fetches and sets using hooks the complete list of private pathways
  useEffect(() => {
    void (async () => {
      const [res, err] = await wrapPromise(listPathways(userToken))
      // if an error is found return without setting values to array
      if (err !== null) return

      setStateValues(res)
    })()
  }, [])
  // fetches and sets using hooks the complete list of categories
  useEffect(() => {
    void (async () => {
      // api function that returns the list of categories from the categories table, or an error message if none found
      const [res, err] = await wrapPromise(listCategories())
      // if an error is found return without setting values to array
      if (err !== null) return
      // updates the array with a new value without overiding the previous values
      setStateValues2(stateOptionsCat => [...stateOptionsCat, ...res])
      setStateValues3(res)
    })()
  }, [])
  // sets an array const that uses the groupby import to sort the pathways in the array based on the category id
  const result = _.groupBy(stateOptionsPath, path => path.catID)
  return (
    <div id="categories">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
        <Title color="black">Pathway Overview</Title>
        <SmallText color="grey">A page for managing pathways and categories</SmallText>
      </div>
      <div id="options" style={{ paddingLeft: '1%', paddingRight: '1%' }}>
        <OptionButtonRow1 id="buttonInput" type="button" value="Add Category" onClick={toggleCategoryAdd} />
        {isOpenCatAdd && <CategoryAddPopup />}
        <OptionButtonRow1 id="buttonInput" type="button" value="Add Pathway" onClick={togglePathwayAdd} />
        {isOpenPathAdd && <PathwayAddPopup />}
        <OptionButtonRow1 id="buttonInput" type="button" value="Delete Category" onClick={toggleCategoryDelete} />
        {isOpenCatDelete && <CategoryDeletePopup />}
        <OptionButtonRow1 id="buttonInput" type="button" value="Delete Pathway" onClick={togglePathwayDelete} />
        {isOpenPathDelete && <PathwayDeletePopup />}
      </div>
      <div id="options" style={{ paddingLeft: '1%', paddingRight: '1%', marginTop: '1rem' }}>
        <OptionButtonRow2 id="buttonInput" type="button" value="Download All Pathways" onClick={downloadAllPathways} />
        <OptionButtonRow2 id="buttonInput" type="button" value="Restore Pathway" onClick={togglePathwayRestore} />
        {isOpenPathRestore && <PathwayRestorePopup />}
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem', paddingTop: '40px' }}
      >
        <SubTitle color="black">Pathways</SubTitle>
        <SmallText color="grey">A list of pathways separated by category</SmallText>
      </div>
      <div className="container">
        {Object.entries(result).map(([id, path]) => {
          return (
            <div className="dep" key={id}>
              <Collapsible open={false} title={catName(Number(id))}>
                <PathTable>
                  <table>
                    <thead>
                      <tr>
                        <th>Pathway ID</th>
                        <th>Pathway Name</th>
                        <th>Pathway Description</th>
                        <th>Pathway Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      {path.map(pathway => {
                        return (
                          <tr
                            key={pathway.pathID}
                            onClick={() => {
                              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                              window.location.href = `/admin/creation-tool/${pathway.pathID}`
                            }}
                          >
                            <td>{pathway.pathID}</td>
                            <td>{pathway.pathName}</td>
                            <td>{pathway.pathDescription}</td>
                            <td>
                              <LinkButton
                                onClick={event => {
                                  navigator.clipboard
                                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    .writeText(`https://www.sendecisions.org.uk/pathway-display/${pathway.pathID}`)
                                    .catch(err => reject(err))
                                  toast.info(`Link to ${pathway.pathName} copied`)
                                  event.stopPropagation()
                                }}
                              >
                                Copy Link To Pathway
                              </LinkButton>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </PathTable>
              </Collapsible>
            </div>
          )
        })}
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem', paddingTop: '40px' }}
      >
        <SubTitle color="black">Categories</SubTitle>
        <SmallText color="grey">A list of the current catgories and their ID</SmallText>
      </div>
      <PathTable>
        <table>
          <thead>
            <tr>
              <th>Category ID</th>
              <th>Category Name</th>
            </tr>
          </thead>
          <tbody>
            {stateOptionsCat2.map(category => {
              // maps the elements in the category array to a table entry
              return (
                <tr key={category.catID}>
                  <td>{category.catID}</td>
                  <td>{category.catName}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </PathTable>
      <ToastContainer position="bottom-center" pauseOnHover={false} />
    </div>
  )
}
