import React, { useCallback, useEffect, useState } from 'react'
import {
  addEdge,
  ArrowHeadType,
  Connection,
  Edge,
  Elements,
  getIncomers,
  getOutgoers,
  isNode,
  Node,
  OnLoadFunc,
  OnLoadParams,
  ReactFlowProvider,
  removeElements
} from 'react-flow-renderer/nocss'
// you need these styles for React Flow to work properly
import 'react-flow-renderer/dist/style.css'
// load the default theme
import 'react-flow-renderer/dist/theme-default.css'

import styled from 'styled-components'

import { EditingPanel } from './components/editing-panel'
import { Toolbar } from './components/toolbar'
import { useDnDNode } from './hooks'
import { PathwaysCanvas } from '../../components/pathways-canvas'
import { Button } from '../../components/button'
import { AiOutlineUndo } from 'react-icons/ai'
// Toast notification dependencies
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Container = styled.div`
  display: flex;

  width: 100%;
  height: 94vh;

  background-color: #f5f5f5;

  .react-flow {
    height: 100%;
  }
`
const ContainerEdit = styled.main`
  position: fixed;
  z-index: 10;
  justify-content: space-between;
  align-items: center;

  display: flex;

  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  padding: 0 8.75rem;

  pointer-events: none;
`

const EditToolsContainer = styled.div`
  display: flex;

  pointer-events: auto;
`

export const CreationTool: React.FC<IProps> = ({ pathway, category, isPublished, setPublished }) => {
  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams | null>(null)
  const [elements, setElements] = useState<Elements<CustomNodeData>>(pathway.Data)
  const [selectedNode, setSelectedNode] = useState<Node<CustomNodeData> | null>(null)
  const [selectedNodeTitle, setSelectedNodeTitle] = useState<string>('')
  const [selectedNodeContent, setSelectedNodeContent] = useState<string>('')
  const [selectedNodeType, setSelectedNodeType] = useState<string>('')
  const [selectedNodeStyle, setSelectedNodeStyle] = useState<CustomNodeStyle | null>(null)
  const { onDropNode, onDragNodeOver, canvasContainer } = useDnDNode(reactFlowInstance)
  const { Name: name } = pathway
  const { Name: categoryName } = category
  const [deletedNodeArray, setDeletedNodeArray] = useState<Array<Node<CustomNodeData>>>([])

  /**
   * Called when the undo button is pressed
   */
  const undoHandle = (): void => {
    if (deletedNodeArray[0] === undefined) {
      toast.info('No Changes to Undo')
      return
    }
    const node: Node<CustomNodeData> = deletedNodeArray[0] // Create a new node containing the data of the deleted node
    setElements(els => [...els, node]) // Add the new node to the array of elements
    // Remove the restored node from the array
    const array = deletedNodeArray
    array.splice(0, 1) // Remove the last element
    setDeletedNodeArray(array) // Update the state to remove the restored node
    toast.success('Change Undone Successfully')
  }

  useEffect(() => {
    setElements(els =>
      els.map(el => {
        // Update the title of the currently selected node
        if (el.id === selectedNode?.id && el.data !== undefined) {
          el.data = { ...el.data, title: selectedNodeTitle }
        }

        return el
      })
    )
  }, [selectedNode, selectedNodeTitle])

  useEffect(() => {
    setElements(els =>
      els.map(el => {
        // Update the content of the currently selected node
        if (el.id === selectedNode?.id && el.data !== undefined) {
          el.data = { ...el.data, content: selectedNodeContent }
        }

        return el
      })
    )
  }, [selectedNode, selectedNodeContent])

  useEffect(() => {
    if (selectedNodeStyle === null) return

    setElements(els =>
      els.map(el => {
        // Update the style of the currently selected node
        if (el.id === selectedNode?.id && el.data !== undefined) {
          el.data = { ...el.data, style: selectedNodeStyle }
        }

        return el
      })
    )
  }, [selectedNode, selectedNodeStyle])

  useEffect(() => {
    setElements(els =>
      els.map(el => {
        // Update the type of the currently selected node
        if (el.id === selectedNode?.id) {
          el.type = selectedNodeType // change its type to selectedNodeType
        }
        return el
      })
    )
  }, [selectedNode, selectedNodeType])

  /**
   * Handles establishing a new connection from a source to a target node
   * @param params The data associated with the established connection, such as source and target node as well as which handles
   *               were used
   */
  const onConnect = useCallback((params: Edge | Connection): void => {
    const { source, sourceHandle, target } = params

    setElements(els => {
      let newEls = els

      // Attempt to find the edge's source and target nodes in the graph
      const sourceNode = newEls.find(el => isNode(el) && el.id === source) as Node<CustomNodeData> | undefined
      const targetNode = newEls.find(el => isNode(el) && el.id === target) as Node<CustomNodeData> | undefined

      // Update the n. of outgoing/incoming edges only if the source and target nodes exist
      if (sourceNode !== undefined && targetNode !== undefined) {
        newEls = registerEdge(sourceNode, targetNode, els)
      }

      // Construct the appropriate edge according to which source handle was used
      let edge: Edge | Connection
      switch (sourceHandle) {
        case 'yes-handle':
          edge = {
            ...params,
            type: 'decision-edge',
            data: { text: 'Yes' },
            arrowHeadType: ArrowHeadType.ArrowClosed
          }
          break
        case 'no-handle':
          edge = {
            ...params,
            type: 'decision-edge',
            data: { text: 'No' },
            arrowHeadType: ArrowHeadType.ArrowClosed
          }
          break
        default:
          edge = {
            ...params,
            type: 'step',
            arrowHeadType: ArrowHeadType.ArrowClosed
          }
          break
      }

      // Add the edge to the graph
      return addEdge(edge, newEls)
    })
  }, [])

  /**
   * Handles the removal of elements on the Creation Tool
   * @param elementsToRemove array of elements which are set to be removed
   */
  const onElementsRemove = useCallback((elementsToRemove: Elements): void => {
    setElements(els => {
      let newEls = els

      elementsToRemove.forEach(elToRemove => {
        if (!isNode(elToRemove)) return

        // Attempt to find the nodes connected to the node to remove
        const nodeToRemove = elToRemove as Node<CustomNodeData>
        const incomingNodes: Array<Node<CustomNodeData>> = getIncomers(nodeToRemove, newEls)
        const outgoingNodes: Array<Node<CustomNodeData>> = getOutgoers(nodeToRemove, newEls)

        if (nodeToRemove.data !== undefined) {
          setDeletedNodeArray(nds => [nodeToRemove, ...nds]) // Place the most recently deleted at the front of the array
        }

        // Update the n. of outgoing/incoming edges of the interested nodes
        incomingNodes.forEach(node => {
          newEls = unregisterEdge(node, nodeToRemove, newEls)
        })
        outgoingNodes.forEach(node => {
          newEls = unregisterEdge(nodeToRemove, node, newEls)
        })
      })

      // Remove the element(s) from the graph
      return removeElements(elementsToRemove, newEls)
    })
  }, [])

  /**
   * Handles the selection of Elements on the Creation Tool
   * @param _evt User mouse event
   * @param el Element to be edited, either Node or Edge
   */
  const onElementClick = useCallback((_evt: React.MouseEvent, el: Node | Edge): void => {
    if (!isNode(el)) return

    // Update the currently selected node and its content
    setSelectedNode(_ => el)
    setSelectedNodeTitle(_ => el.data?.title ?? '')
    setSelectedNodeContent(_ => el.data?.content.replace(/<p><br><\/p><ul>/g, '<ul>') ?? '') // Remove additional line breaks which is a know bug with react-quill
    setSelectedNodeStyle(_ => el.data?.style ?? null)
    setSelectedNodeType(_ => el.type ?? '')
  }, [])

  /**
   * Handles the loading of the ReactFlow instance for graphing
   * @param _reactFlowInstance The instance of ReactFlow
   */
  const onLoad: OnLoadFunc = useCallback(_reactFlowInstance => {
    setReactFlowInstance(_reactFlowInstance)
  }, [])

  /**
   * Handles the movement of Nodes on the CreationTool
   * @param _evt User mouse event
   * @param node The Node which is set to be translated
   */
  const onNodeDrag = useCallback((_evt: React.MouseEvent, node: Node): void => {
    // Update the position of the node that is being moved around the canvas
    setElements(els => els.map(el => (el.id === node.id ? node : el)))
  }, [])

  return (
    <Container>
      <ReactFlowProvider>
        <Toolbar pathwayName={name} categoryName={categoryName} />
        <ContainerEdit>
          <EditToolsContainer>
            <Button.Icon size="3rem" onClick={undoHandle}>
              <AiOutlineUndo size="1.75rem" />
            </Button.Icon>
          </EditToolsContainer>
        </ContainerEdit>

        <EditingPanel
          currentElements={elements}
          currentPathway={pathway}
          currentCategory={category}
          selectedNodeTitle={selectedNodeTitle}
          selectedNodeContent={selectedNodeContent}
          selectedNodeStyle={selectedNodeStyle}
          selectedNodeType={selectedNodeType}
          setSelectedNodeTitle={setSelectedNodeTitle}
          setSelectedNodeContent={setSelectedNodeContent}
          setSelectedNodeStyle={setSelectedNodeStyle}
          setSelectedNodeType={setSelectedNodeType}
          isPublished={isPublished}
          setPublished={setPublished}
        />

        <PathwaysCanvas
          readOnly={false}
          elements={elements}
          containerRef={canvasContainer}
          onConnect={onConnect}
          onElementsRemove={onElementsRemove}
          onElementClick={onElementClick}
          onLoad={onLoad}
          onDragOver={onDragNodeOver}
          onDrop={e => onDropNode(e, node => setElements(els => [...els, node]))}
          onNodeDrag={onNodeDrag}
        />
        <ToastContainer position="bottom-center" pauseOnHover={false} />
      </ReactFlowProvider>
    </Container>
  )
}

interface IProps {
  pathway: IPathway
  category: ICategory
  isPublished: boolean
  setPublished: (value: React.SetStateAction<boolean>) => void
}

/**
 * Registers a new edge between two nodes by incrementing the n. of outgoing edges in the source node and the n. of incoming
 * edges in the target node
 * @param sourceNode The node from which the edge begins
 * @param targetNode The node to which the edge ends
 * @param els The graph to update
 * @returns The updated state of the graph
 */
function registerEdge(
  sourceNode: Node<CustomNodeData>,
  targetNode: Node<CustomNodeData>,
  els: Elements<CustomNodeData>
): Elements {
  const outgoingEdges = sourceNode.data?.outgoingEdges ?? 0
  const incomingEdges = targetNode.data?.incomingEdges ?? 0

  // Update source and target node with the new n. of edges
  const newSourceNode = {
    ...sourceNode,
    data: {
      ...sourceNode.data,
      outgoingEdges: outgoingEdges + 1
    }
  }
  const newTargetNode = {
    ...targetNode,
    data: {
      ...targetNode.data,
      incomingEdges: incomingEdges + 1
    }
  }

  return els.map(el => {
    if (el.id === sourceNode.id) return newSourceNode
    if (el.id === targetNode.id) return newTargetNode
    return el
  })
}

/**
 * Unregisters an existing edge between two nodes by decrementing the n. of outgoing edges in the source node and the n. of
 * incoming edges in the target node
 * @param sourceNode The node from which the edge begins
 * @param targetNode The node to which the edge ends
 * @param els The graph to update
 * @returns The updated state of the graph
 */
function unregisterEdge(
  sourceNode: Node<CustomNodeData>,
  targetNode: Node<CustomNodeData>,
  els: Elements<CustomNodeData>
): Elements {
  const outgoingEdges = sourceNode.data?.outgoingEdges
  const incomingEdges = targetNode.data?.incomingEdges

  // Update source and target node with the new n. of edges
  const newSourceNode = {
    ...sourceNode,
    data: {
      ...sourceNode.data,
      outgoingEdges: outgoingEdges !== undefined ? outgoingEdges - 1 : 0
    }
  }
  const newTargetNode = {
    ...targetNode,
    data: {
      ...targetNode.data,
      incomingEdges: incomingEdges !== undefined ? incomingEdges - 1 : 0
    }
  }

  return els.map(el => {
    if (el.id === sourceNode.id) return newSourceNode
    if (el.id === targetNode.id) return newTargetNode
    return el
  })
}
