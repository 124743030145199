import axios from 'axios'

/**
 * Base object to perform requests to the SENPathways API.
 * All methods that interact with such an API should use this instance as the base building block to construct requests since
 * it (will) automatically handles authentication and authorization, base API URL and much more
 */
export const apiInstance = axios.create({
  baseURL: 'https://api.sendecisions.org.uk/'
})

// Intercept 401 Unauthorized responses and redirect the user to login screen
apiInstance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response
  },
  async error => {
    if (error.request.status === 401) {
      window.location.href = '/admin/login'
    }
    return await Promise.reject(error)
  }
)
