import React, { useCallback, useRef } from 'react'
import { Node, OnLoadParams } from 'react-flow-renderer/nocss'
// you need these styles for React Flow to work properly
import 'react-flow-renderer/dist/style.css'
// load the default theme
import 'react-flow-renderer/dist/theme-default.css'

import { v4 as uuidv4 } from 'uuid'

/**
 * Handles dragging and dropping nodes
 * @param reactFlowInstance The current instance of the react-flow graph
 */
export function useDnDNode(reactFlowInstance: OnLoadParams | null): DnDNodeHook {
  const canvasContainer = useRef<HTMLDivElement>(null)

  /**
   * Initiates a drag-and-drop interaction
   * @param evt The corresponding drag event
   * @param nodeType The type of node to add to the canvas once the draggable element is dropped
   */
  const onDragNode = useCallback((evt: React.DragEvent, nodeType: CustomNodeType): void => {
    evt.dataTransfer.setData('application/reactflow', nodeType)
    evt.dataTransfer.effectAllowed = 'move'
  }, [])

  /**
   * Controls the interaction while dragging a node
   * @param evt The corresponding drag event
   */
  const onDragNodeOver: React.DragEventHandler = useCallback(evt => {
    evt.preventDefault()
    evt.dataTransfer.dropEffect = 'move'
  }, [])

  /**
   * Terminates a drag-and-drop interaction and performs the given action
   * @param evt The corresponding drag event
   * @param cb The function to call with the newly created node when the drop interaction ends
   */
  const onDropNode = useCallback(
    (evt: React.DragEvent, cb: (node: Node<CustomNodeData>) => void): void => {
      evt.preventDefault()
      if (canvasContainer.current === null || reactFlowInstance === null) return

      const nodeType = evt.dataTransfer.getData('application/reactflow') as CustomNodeType
      const canvasBounds = canvasContainer.current.getBoundingClientRect()
      const position = reactFlowInstance.project({
        x: evt.clientX - canvasBounds.left,
        y: evt.clientY - canvasBounds.top
      })

      const node: Node<CustomNodeData> = {
        id: uuidv4(),
        type: nodeType,
        position,
        data: {
          title: `${nodeType === 'information-node' ? 'Information' : 'Decision'} node`,
          content: '',
          style: {
            borderStyle: 'solid',
            borderColor: '#212121'
          },
          outgoingEdges: 0,
          incomingEdges: 0
        }
      }

      cb(node)
    },
    [canvasContainer, reactFlowInstance]
  )

  return { onDragNode, onDropNode, onDragNodeOver, canvasContainer }
}

interface DnDNodeHook {
  onDragNode: (evt: React.DragEvent, nodeType: CustomNodeType) => void
  onDragNodeOver: React.DragEventHandler
  onDropNode: (evt: React.DragEvent, cb: (node: Node<CustomNodeData>) => void) => void
  canvasContainer: React.RefObject<HTMLDivElement>
}
