/**
 * Decision Node inherits from CustomNodeBase. It has specialised handles at its right and bottom which can be used to create
 * custom edges to indicate decision making.
 */
import React from 'react'
import { Handle, Node, Position } from 'react-flow-renderer/nocss'
// you need these styles for React Flow to work properly
import 'react-flow-renderer/dist/style.css'
// load the default theme
import 'react-flow-renderer/dist/theme-default.css'

import { CustomNodeBase } from '../CustomNodeBase'
import { HTMLRenderer } from '../HTMLRenderer'
import '../CustomReactFlow.css'

export const DecisionNode: React.FC<IProps> = props => {
  if (props.data === undefined) {
    return null // return if data is left undefined
  }

  const { content, title, style } = props.data
  return (
    <CustomNodeBase title={title} nodeStyle={style} {...props}>
      <Handle type="target" id="left-handle" position={Position.Left} style={{ background: '#555' }} isConnectable />
      <Handle type="target" id="top-handle" position={Position.Top} style={{ background: '#555' }} isConnectable />
      {/* Define the yes handle for the yes  decision edge */}
      <Handle type="source" id="yes-handle" position={Position.Right} style={{ background: '#09d85f' }} isConnectable />
      {/* Define the no handle for the no decision edge */}
      <Handle type="source" id="no-handle" position={Position.Bottom} style={{ background: '#cf0e0e' }} isConnectable />

      <HTMLRenderer content={content} />
    </CustomNodeBase>
  )
}

interface IProps extends Node<CustomNodeData> {}
