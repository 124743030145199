/**
 * Defines a normal button with Text
 */
import styled from 'styled-components'

import { Defaults } from '../../components/defaults'

/**
 * Defines styling of text
 */
export const TextButton = styled(Defaults.Button)<IProps>`
  transition: 175ms box-shadow ease-in-out, 175ms background-color ease-in-out, 175ms color ease-in-out;

  box-shadow: none;
  border-radius: 0.25rem;
  padding: 0.375rem 0.625rem;

  background-color: ${({ solid }) => (solid !== undefined ? '#00A2FF' : 'none')};
  color: ${({ solid }) => (solid !== undefined ? '#ffffff' : '#bbbbbb')};

  &:hover,
  &:focus {
    box-shadow: ${({ solid }) => (solid !== undefined ? '0px 4px 16px 8px rgba(0, 0, 0, 0.025)' : 'none')};

    background-color: ${({ solid }) => (solid !== undefined ? '#0084f0' : 'none')};
    color: ${({ solid }) => (solid !== undefined ? '#ffffff' : '#000000')};
  }

  &:disabled {
    background-color: ${({ solid }) => (solid !== undefined ? '#bdbdbd' : 'none')};

    cursor: not-allowed;
  }
`
/**
 * Defines properties of button
 */
interface IProps {
  solid?: boolean
}
