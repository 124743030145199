import { apiInstance } from '../axios'

/**
 * Authenticates a given user in the back-end
 * @param username The username of the user
 * @param password The password of the user
 * @returns The response, either successful or not, from the API
 */
export const loginUser = async (username: string, password: string): Promise<ITokenResponse> => {
  return await new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('username', username)
    formData.append('password', password)

    apiInstance
      .post('/users/token', formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => resolve(res.data))
      .catch(err => reject(err.response))
  })
}

/**
 * Gets the details of a user given their access token
 * @param accessToken The access token of the user to fetch
 * @returns The response, either successful or not, from the API
 */
export const getUser = async (accessToken: string): Promise<ICurrentResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .get('/users/current', {
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      })
      .then(res => resolve(res.data))
      .catch(err => reject(err.response))
  })
}

/**
 * Updates a user's details
 * @param username Username to be updated
 * @param name Name to be updated
 * @param password Password to authenticate change
 * @param accessToken AccessToken to authenticate change
 * @returns The response, either successful or not, from the API
 */
export const updateUser = async (
  username: string,
  name: string,
  password: string,
  accessToken: string
): Promise<ICurrentResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(
        '/users/update',
        {
          Username: username,
          Name: name,
          Password: password
        },
        { headers: { authorization: `Bearer ${accessToken}` } }
      )
      .then(res => resolve(res.data))
      .catch(err => reject(err.response))
  })
}

export const setAdminUser = async (username: string, admin: boolean, accessToken: string): Promise<ICurrentResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(
        '/users/set-admin',
        {
          Username: username,
          Admin: admin
        },
        { headers: { authorization: `Bearer ${accessToken}` } }
      )
      .then(res => resolve(res.data))
      .catch(err => reject(err.response))
  })
}

export const deleteUser = async (username: string, accessToken: string): Promise<ICurrentResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(
        '/users/delete',
        {
          Username: username
        },
        { headers: { authorization: `Bearer ${accessToken}` } }
      )
      .then(res => resolve(res.data))
      .catch(err => reject(err.response))
  })
}

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
  accessToken: string
): Promise<ICurrentResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(
        '/users/change-password',
        {
          CurrentPassword: oldPassword,
          NewPassword: newPassword
        },
        { headers: { authorization: `Bearer ${accessToken}` } }
      )
      .then(res => resolve(res.data))
      .catch(err => reject(err.response))
  })
}

export const newUser = async (
  username: string,
  name: string,
  admin: boolean,
  password: string,
  accessToken: string
): Promise<ICurrentResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(
        '/users/new',
        {
          Username: username,
          Name: name,
          Admin: admin,
          Password: password
        },
        { headers: { authorization: `Bearer ${accessToken}` } }
      )
      .then(res => resolve(res.data))
      .catch(err => reject(err.response))
  })
}

export const listUser = async (accessToken: string): Promise<ICurrentResponseCap[]> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .get<ICurrentResponseCap[]>('/users/list', { headers: { authorization: `Bearer ${accessToken}` } })
      .then(res => {
        resolve(res.data)
      })
      .catch(reject)
  })
}

interface ITokenResponse {
  access_token: string
  token_type: string
}

interface ICurrentResponseCap {
  Username: string
  Name: string
  Admin: boolean
}

interface ICurrentResponse {
  username: string
  name: string
  admin: boolean
}
