import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { readPathway as readPrivatePathway } from '../../api/pathways/private'
import { readPathway as readPublicPathway } from '../../api/pathways/public'
import { readCategory } from '../../api/categories'
import { CreationTool } from '../../views/creation-tool'
import { wrapPromise } from '../../utils/promise'
import { useParams } from 'react-router-dom' // react-router is for page routing

const Container = styled.div`
  width: 100%;
  height: 2rem;
`

export const CreationToolSetup: React.FC = () => {
  const { id } = useParams() // the exported object is destructured as an id
  const PATHWAY_ID = Number(id) ?? 1 // either use id as PATHWAY_ID or default to 1
  const [pathway, setPathway] = useState<IPathway | null>(null)
  const [category, setCategory] = useState<ICategory | null>(null)
  const [isPublished, setPublished] = useState<boolean>(false)
  const userToken = localStorage.getItem('userToken') ?? ''

  useEffect(() => {
    // Fetch pathway on component mount
    void (async () => {
      const [res, err] = await wrapPromise(readPrivatePathway(PATHWAY_ID, userToken)) // read pathway, return response
      if (err !== null) return

      setPathway(_ => ({
        ID: res.pathID,
        Name: res.pathName,
        Description: res.pathDescription,
        Data: res.pathData,
        catID: res.catID
      }))
      const [resCat, errCat] = await wrapPromise(readCategory(res.catID))
      if (errCat !== null) return

      setCategory(_ => ({
        ID: resCat.catID,
        Name: resCat.catName
      }))

      // Check if pathway is present in public table (has been published)
      const [_, errPub] = await wrapPromise(readPublicPathway(PATHWAY_ID))
      if (errPub == null) {
        setPublished(true)
      }
    })()
  }, [])

  if (pathway === null || category === null) return null

  return (
    <Container>
      <CreationTool pathway={pathway} category={category} isPublished={isPublished} setPublished={setPublished} />
    </Container>
  )
}
