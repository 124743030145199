import { IReadPathwayResponse, sanitizePathData, IReadPathwayAttributes } from '../shared'
import { apiInstance } from '../../axios'

/**
 * Fetches a specific pathway
 * @param id The ID of the pathway
 * @returns The response, either successful or not, from the API
 */
export const readPathway = async (id: number): Promise<IReadPathwayResponse> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .get<IReadPathwayResponse>(`/pathways/public/get/${id}`)
      .then(res => {
        res.data.pathData = sanitizePathData(res.data.pathData)
        resolve(res.data)
      })
      .catch(reject)
  })
}
export const listPathways = async (accessToken: string): Promise<IReadPathwayAttributes[]> => {
  return await new Promise((resolve, reject) => {
    apiInstance // use api
      .get<IReadPathwayAttributes[]>('/pathways/public/list', { headers: { authorization: `Bearer ${accessToken}` } }) // get list of pathways
      .then(res => resolve(res.data)) // if fufilled then return response data
      .catch(reject) // otherwise return error
  })
}
/**
 * Gets the date the public pathways were last updated
 * @returns The response, either successful or not, from the API
 */
export const getLastUpdated = async (): Promise<string> => {
  return await new Promise(resolve => {
    void apiInstance.get<string>('/pathways/public/last-updated').then(res => resolve(res.data))
  })
}
