/**
 * Create custom Dropdown Input
 */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState } from 'react'
import { AiOutlineCaretDown } from 'react-icons/ai'
import styled from 'styled-components'

import { DropdownText } from '../../components/typography'

const DropdownContainer = styled.div`
  position: relative;

  border-radius: 0.25rem;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem 0.375rem;

  background-color: #f5f5f5;

  cursor: pointer;
`

const DropdownHeader = styled.div`
  justify-content: space-between;
  align-items: center;

  display: flex;
`
// Rotates caret depending on whether Dropdown is open or not
const DropdownIcon = styled(AiOutlineCaretDown)<{ open: boolean }>`
  transform: rotate(${p => (p.open ? '0deg' : '-90deg')});

  transition: transform 200ms ease-in-out;
`

const DropdownBody = styled.div`
  position: absolute;

  display: block;

  margin-top: 0.5rem;
  margin-left: -0.375rem;
  margin-right: -0.375rem;
  border: 1px solid #e5e8ec;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  background-color: #f5f5f5;

  z-index: 1000;
`

const DropdownItem = styled.div`
  align-items: center;

  display: flex;

  padding: 0.25rem 0.375rem;
`

const DropdownItemDot = styled.span<{ selected: boolean }>`
  opacity: ${p => (p.selected ? 1 : 0)};

  transition: opacity 200ms ease-in-out;

  margin-right: 0.25rem;

  color: #212121;
`

export function DropdownInput<T>(props: IProps<T>): JSX.Element {
  const { placeholder, options, selectedOption, setSelectedOption } = props
  const [isOpen, setOpen] = useState(false)
  const toggleDropdown = (): void => setOpen(!isOpen)

  return (
    <DropdownContainer>
      <DropdownHeader onClick={toggleDropdown}>
        {selectedOption !== null ? options[selectedOption].label : <DropdownText>{placeholder}</DropdownText>}
        <DropdownIcon size="0.75rem" open={isOpen} />
      </DropdownHeader>

      {isOpen && (
        <DropdownBody>
          {/* Maps every item in a given array to an option, returns option if clicked */}
          {options.map((item, index) => (
            <DropdownItem
              onClick={_ => {
                setSelectedOption(index)
                setOpen(!isOpen)
              }}
              key={index}
            >
              <DropdownItemDot selected={index === selectedOption}>•</DropdownItemDot>
              {item.label}
            </DropdownItem>
          ))}
        </DropdownBody>
      )}
    </DropdownContainer>
  )
}

interface IProps<T> {
  placeholder: string
  options: Array<IDropdownOption<T>>
  selectedOption: number | null
  setSelectedOption: (value: number | null) => void
}

export interface IDropdownOption<T> {
  value: T
  label: React.ReactNode
}
