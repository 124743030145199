/**
 * Utility function to wrap the resolution of a promise that could throw errors.
 * It avoids the try/catch "tower of terror" by returning a tuple that contains, in order, the resolved value of the promise on
 * success or null on error, and null on success or an error otherwise.
 * Furthermore, it provides a unified approach to handling errors that could be, for example, logged to an external service
 * @param prom The promise to resolve
 * @returns A promise that resolves according to the description given earlier
 */
export async function wrapPromise<T>(prom: Promise<T>): Promise<[T, any | null]> {
  try {
    const res = await prom
    return [res, null]
  } catch (e: unknown) {
    console.error(e)

    // any is required in this context because you wouldn't access the value
    // of the promise when an error occurs
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return [null as any, e]
  }
}
