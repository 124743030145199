/**
 * Define a Custom Node Base. Nodes inherit properties and stylings from Base.
 */
import React, { memo } from 'react'
import styled from 'styled-components'

import { Card } from '../../../../components/card'
import { SmallText } from '../../../../components/typography'

/**
 * Define styling of a base node
 */
const Container = styled(Card.Container)<{ nodeStyle: CustomNodeStyle }>`
  border: 0.0625rem ${({ nodeStyle }) => nodeStyle.borderStyle} ${({ nodeStyle }) => nodeStyle.borderColor};
  min-width: ${({ nodeStyle }) => (nodeStyle.width === undefined ? '14rem' : nodeStyle.width)};
  max-width: ${({ nodeStyle }) => (nodeStyle.width === undefined ? '14rem' : nodeStyle.width)};

  .selected > & {
    box-shadow: 0px 4px 16px 8px rgba(0, 0, 0, 0.075);

    /* Remove the additional width added by the border from the padding to avoid resizing the elements (and messing its
       position) */
    border-width: 0.125rem;
    padding: calc(1rem - 0.0625rem) calc(1.25rem - 0.0625rem);
  }
`
/**
 * Exports node base, requires a title and content, custom node styling can also be applied
 */
export const CustomNodeBase: React.FC<IProps> = memo(({ children, title, nodeStyle, ...props }) => (
  <Container nodeStyle={nodeStyle} {...props}>
    <Card.Header>
      <SmallText style={{ fontSize: '1.3rem' }} color="#652a96" bold>
        {title}
      </SmallText>
    </Card.Header>

    <Card.Content>{children}</Card.Content>
  </Container>
))

interface IProps {
  title: string // title of the node
  nodeStyle: CustomNodeStyle // custom styling for the node can be applied
}
