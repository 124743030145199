import React, { useState, useEffect, useCallback } from 'react'
import { ReactFlowProvider, OnLoadParams, OnLoadFunc } from 'react-flow-renderer/nocss'
// you need these styles for React Flow to work properly
import 'react-flow-renderer/dist/style.css'
// load the default theme
import 'react-flow-renderer/dist/theme-default.css'

import styled from 'styled-components'
import { PathwaysCanvas } from '../../components/pathways-canvas'
import { Text } from '../../components/typography'
import { OnLoad } from './components/on-load'

const Container = styled.div`
  display: flex;

  width: 100%;
  height: 100%;

  // Make the node handles invisible

  .react-flow__handle {
    background: transparent !important;
    border-radius: 0;
    border: 0;
    height: 1.9rem;
    right: 0;
    width: 1.9rem;
    cursor: default;
  }

  .react-flow__handle::after {
    background-color: transparent;
    border-radius: 100%;
    border: none;
    content: '';
    display: block;
    height: 0.6rem;
    position: absolute;
    top: 50%;
    width: 0.6rem;
  }

  div[data-handleid='yes-handle']::after {
    background-color: transparent;
  }

  div[data-handleid='no-handle']::after {
    background-color: transparent;
  }
  // Use the default cursor when hovering over a node
  .react-flow__node {
    cursor: default;
  }
`

const TitleContainer = styled.div`
  position: fixed;
  z-index: 10;
  left: 50%;
  align-items: baseline;

  display: flex;
  padding-top: 1vh;
  padding-bottom: 1vh;

  transform: translate(-50%);

  pointer-events: auto;
  @media (orientation: portrait) {
    width: 100%;
    left: 70%;
  }
`

const FeedbackContainer = styled.div`
  background: white;
  position: absolute;
  width: 17rem;
  height: 2.25rem;
  z-index: 10;
  left: 50%;
  bottom: 7%;

  border-radius: 0.5rem;
  border: solid 0.15rem deepskyblue;

  transform: translate(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PathwayDisplay: React.FC<IProps> = ({ pathway, category }) => {
  const [_reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams | null>(null)
  const elements = pathway.Data
  const [isMobile, setIsMobile] = useState(false)

  const handleResize = (): void => {
    if (window.innerWidth < window.innerHeight) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    handleResize()
  }, [isMobile])

  const onLoad: OnLoadFunc = useCallback(_reactFlowInstance => {
    setReactFlowInstance(_reactFlowInstance)
  }, [])

  return (
    <Container>
      <ReactFlowProvider>
        <TitleContainer>
          <Text style={{ fontSize: isMobile ? '0.75rem' : '1.5rem' }} color="#7a7a7a">
            {category.Name}
          </Text>
          <Text style={{ fontSize: isMobile ? '0.75rem' : '1.5rem' }} color="#7a7a7a">
            &nbsp;/&nbsp;
          </Text>
          <Text style={{ fontSize: isMobile ? '0.75rem' : '1.5rem' }} color="deepskyblue" bold>
            {pathway.Name}
          </Text>
        </TitleContainer>

        <PathwaysCanvas readOnly onLoad={onLoad} elements={elements} />

        <FeedbackContainer>
          <a style={{ fontSize: '1rem' }} href="https://forms.office.com/r/aASAxNc2Tg" target="_blank">
            Click Here To Give Feedback
          </a>
        </FeedbackContainer>
        <OnLoad />
      </ReactFlowProvider>
    </Container>
  )
}

interface IProps {
  pathway: IPathway
  category: ICategory
}
