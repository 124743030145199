/**
 * Defines the Acknowledgements page of the website
 */
import React from 'react'
import { Text, Title } from '../../components/typography'
import styled from 'styled-components'

const BodyContainer = styled.div`
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  border: 0.1rem solid deepskyblue;
  height: 100%;
  span {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  @media (orientation: portrait) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

export const Acknowledgements: React.FC = () => {
  return (
    <div>
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem', textAlign: 'center' }}
      >
        <Title>Acknowledgements</Title>
      </div>
      <BodyContainer>
        <Text>
          These pathways were developed by Dr Ben Essex from 2020 - 2023. The website and editing facilities were produced by
          second year students from the Computer Science Department of Nottingham University in 2022. The team consisted of
          George Vettraino, Theshmehan Makeswaran, Andrea Busa, Joshua Banks, Nathaniel Ford, Michael Talabi. The website
          www.sendecisions.org.uk and pathways were taken over by the Law Clinic of Liverpool University in 2023 where the
          pathways are now hosted. They will be regularly updated and continue to be freely available to everyone.
        </Text>
      </BodyContainer>
    </div>
  )
}
