import React from 'react'
import { AiOutlineInfoCircle, AiOutlineQuestionCircle } from 'react-icons/ai'
import styled from 'styled-components'

import { Spacer } from '../../../../../../components/spacer'
import { SmallText } from '../../../../../../components/typography'

const Container = styled.div`
  align-items: center;

  display: flex;

  transition: background-color 150ms ease-in-out;

  border-radius: 0.25rem;
  padding: 0.125rem 0.25rem;

  background-color: transparent;

  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`

export const NodesListItem: React.FC<IProps> = ({ type, title, ...props }) => (
  <Container {...props}>
    {type === 'information-node' ? <AiOutlineInfoCircle size="1.25rem" /> : <AiOutlineQuestionCircle size="1.25rem" />}
    <Spacer size="xs" />

    <SmallText>{title}</SmallText>
  </Container>
)

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  type: CustomNodeType
  title: string
}
