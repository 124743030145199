/**
 * Defines the typography for a heading
 */
import styled from 'styled-components'

import { TypographyBase } from './TypographyBase'

export const Heading = styled(TypographyBase)`
  margin-bottom: 0.75rem;

  font-size: 1.5rem;
`
