/**
 * Creates an interface where information about the User is stored
 */
import React, { createContext } from 'react'

export const UserContext = createContext<IUserContextType | null>(null)

interface IUserContextType {
  user: IUser | null
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>
}

export interface IUser {
  admin: boolean // checks if the current user is an admin
  accessToken: string // gets the user's access token, needed to interact with API
  name: string // gets User's name
  username: string // get User's username
}
