/**
 * Define container for a card
 */
import styled from 'styled-components'

export const CardContainer = styled.div`
  box-shadow: 0px 4px 16px 8px rgba(0, 0, 0, 0.025);
  box-sizing: border-box;
  border-radius: 0.5rem;
  width: 100%;
  padding: 1rem 1.25rem;

  background-color: #ffffff;
`
