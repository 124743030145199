/**
 * Defines modal to delete a pathway
 */
import React, { useState, useEffect } from 'react'
import { deletePathway, listPathways } from '../../../api/pathways/private'
import { Title } from '../../../components/typography'
import styled from 'styled-components'
// imports a close button style
import { FaWindowClose } from 'react-icons/fa'
import { wrapPromise } from '../../../utils/promise'
import { toast } from 'react-toastify'

const Button = styled.button`
  background-color: black;
  height: 40px;
  width: 300px;
  color: #fff;
  border-radius: 10px;
`
const Container = styled.div`
  position: fixed;
  background: rgba(158, 158, 158, 0.7);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
`
const Display = styled.div`
  position: center;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 15px;
  padding: 20px;
`
const CloseButton = styled.button`
  margin-top: 1px;
  margin-right: 2px;
  position: absolute;
  top: 3;
  right: 3;
  background-color: black;
  color: white;
  border-radius: 20px;
`

export const PathwayDeletePopup: React.FC = () => {
  const [state, setState] = useState('')
  const userToken = localStorage.getItem('userToken') ?? ''
  const pathwaysList = [{ pathID: 0, pathName: 'one', catID: 0 }] // sets an initial state
  const [stateOptions, setStateValues] = useState(pathwaysList)
  // uses imported functions from backend to delete a category using a given category id
  const deletePathwayButton = async (): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [res, err] = await wrapPromise(deletePathway(Number(getPathID(state)), userToken))
    // returns and displays a message if an error is found
    if (err !== null) {
      toast.error('Category Could Not Be Deleted')
      return
    }
    void closePage()
  }
  // refreshes the page to show the updated table results
  const closePage = (): void => {
    window.location.reload()
  }
  /**
   * This function takes a pathway name as a string and returns a number vlaue of the category id
   * @param name the string name of the category currently selected
   * @returns number category id
   */
  const getPathID = (name: string): number => {
    let i = 0
    while (i !== stateOptions.length) {
      if (stateOptions[i].pathName === name) {
        return stateOptions[i].pathID
      }
      i++
    }
    return 0
  }
  // on page load it uses the backend api to read a list of the pathways and set it using hooks
  useEffect(() => {
    void (async () => {
      const [res, err] = await wrapPromise(listPathways(userToken))
      if (err !== null) return

      setStateValues(res)
    })()
  }, [])
  return (
    <Container>
      <Display>
        <CloseButton onClick={closePage}> {FaWindowClose} x </CloseButton>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Title color="black">Delete Pathway</Title>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <text style={{ padding: '10px', color: 'grey' }}>Select Pathway: </text>
          <select style={{ width: '300px', height: '45px' }} value={state} onChange={e => setState(e.target.value)}>
            {stateOptions.map(pathway => {
              // maps each entry in the pathway array to a selectable element for the display
              return <option value={pathway.pathName}> {pathway.pathName} </option>
            })}
          </select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Button id="dangerButtonInput" onClick={deletePathwayButton}>
            Delete
          </Button>
        </div>
      </Display>
    </Container>
  )
}
