import React, { useEffect, useCallback, useState } from 'react'
import { Elements, Node, useStoreState, useZoomPanHelper } from 'react-flow-renderer/nocss'
// you need these styles for React Flow to work properly
import 'react-flow-renderer/dist/style.css'
// load the default theme
import 'react-flow-renderer/dist/theme-default.css'

import styled from 'styled-components'
import { confirmAlert } from 'react-confirm-alert'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-confirm-alert/src/react-confirm-alert.css'

import { NodesListItem } from './components/nodes-list-item'
import { Card } from '../../../../components/card'
import { Input, IDropdownOption } from '../../../../components/input'
import { Spacer } from '../../../../components/spacer'
import { Heading, Text, DropdownText } from '../../../../components/typography'
import { updatePathway, publishPathway, deletePathway, savePathwayAs, unpublishPathway } from '../../../../api/pathways/private'
import { wrapPromise } from '../../../../utils/promise'
import { ExportedPathway } from '../../../../utils/exportedPathway'
import { listCategories, getCategoryID } from '../../../../api/categories'
import { useNavigate } from 'react-router-dom'

const BORDER_STYLE_OPTIONS: Array<IDropdownOption<_CSSBorderStyle>> = [
  { value: 'solid', label: <DropdownText>Solid</DropdownText> },
  { value: 'dotted', label: <DropdownText>Dotted</DropdownText> },
  { value: 'dashed', label: <DropdownText>Dashed</DropdownText> }
]

const BORDER_COLOR_OPTIONS: Array<IDropdownOption<string>> = [
  { value: '#212121', label: <DropdownText color="#212121">Black</DropdownText> },
  { value: '#FF6663', label: <DropdownText color="#FF6663">Red</DropdownText> },
  { value: '#FEB144', label: <DropdownText color="#FEB144">Orange</DropdownText> },
  { value: '#FDFD97', label: <DropdownText color="#FDFD97">Yellow</DropdownText> },
  { value: '#9EE09E', label: <DropdownText color="#9EE09E">Green</DropdownText> },
  { value: '#9EC1CF', label: <DropdownText color="#9EC1CF">Blue</DropdownText> },
  { value: '#CC99C9', label: <DropdownText color="#CC99C9">Purple</DropdownText> }
]

const NODE_TYPE_OPTIONS: Array<IDropdownOption<string>> = [
  { value: 'information-node', label: <DropdownText>Information</DropdownText> },
  { value: 'double-size-info', label: <DropdownText>Double Size Information</DropdownText> },
  { value: 'decision-node', label: <DropdownText>Decision</DropdownText> }
]
const FOCUS_NODE_ZOOM = 1.25

const Container = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;

  margin-right: 1.5rem;
  box-sizing: border-box;
  width: 360px;
  height: 96vh;
  padding-top: calc(3rem + 1.5rem);
  overflow-y: hidden;
  overflow-x: visible;
`

const ScrollableContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  // Hide the scrollbar in various browsers
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    // Chrome, Safari, Opera
    display: none;
  }
  -ms-overflow-style: none; // IE & Edge
  .ql-tooltip {
    left: 0 !important;
  }
`

const BorderStyleContainer = styled.div`
  grid-template-columns: 1fr 1fr;
  column-gap: 0.75rem;

  display: grid;
`

export const EditingPanel: React.FC<IProps> = ({
  currentElements,
  currentPathway,
  currentCategory,
  selectedNodeTitle,
  selectedNodeContent,
  selectedNodeStyle,
  selectedNodeType,
  setSelectedNodeTitle,
  setSelectedNodeContent,
  setSelectedNodeStyle,
  setSelectedNodeType,
  isPublished,
  setPublished
}) => {
  const [query, setQuery] = useState('')
  const [newPathwayName, setPathwayName] = useState('')
  const { setCenter } = useZoomPanHelper()
  const userToken = localStorage.getItem('userToken') ?? ''
  const nodes = useStoreState(state => state.nodes)
    .filter(node => node.data?.title.toLowerCase().includes(query.toLowerCase()))
    .sort((a, b) => {
      // Sort by alphabetical order
      if (a.data.title < b.data.title) return -1
      if (a.data.title > b.data.title) return 1
      return 0
    })
  const selectedBorderStyle = getSelectedBorderStyle(selectedNodeStyle)
  const selectedBorderColor = getSelectedBorderColor(selectedNodeStyle)
  const selectedNodeInArray = getSelectedNodeType(selectedNodeType)
  const categoriesList = [{ catID: 0, catName: 'one' }]
  const [catname, setCatName] = useState('')
  const [stateCategories, setStateCategories] = useState(categoriesList)
  const navigate = useNavigate()

  /**
   * Fetch a list of categories
   */
  useEffect(() => {
    void (async () => {
      const [res, err] = await wrapPromise(listCategories())
      if (err !== null) return

      setStateCategories(res)
      for (const result of res) {
        if (result.catID === currentPathway.catID) {
          setCatName(result.catName)
          break
        }
      }
    })()
  }, [])

  /**
   * Updates the border style of the selected node
   * @param newIndex The index of the newly selected border style in the array of options or `null`
   */
  const setSelectedBorderStyle = useCallback(
    (newIndex: number | null): void => {
      if (newIndex === null) return

      setSelectedNodeStyle(prevStyle => {
        if (prevStyle === null) return null

        return {
          ...prevStyle,
          borderStyle: BORDER_STYLE_OPTIONS[newIndex].value
        }
      })
    },
    [BORDER_STYLE_OPTIONS]
  )

  /**
   * Updates the border color of the selected node
   * @param newIndex The index of the newly selected border color in the array of options or `null`
   */
  const setSelectedBorderColor = useCallback(
    (newIndex: number | null): void => {
      if (newIndex === null) return

      setSelectedNodeStyle(prevStyle => {
        if (prevStyle === null) return null

        return {
          ...prevStyle,
          borderColor: BORDER_COLOR_OPTIONS[newIndex].value
        }
      })
    },
    [BORDER_COLOR_OPTIONS]
  )

  /**
   * Updates the node type of the selected node
   * @param newIndex The index of the newly selected node type in the array of options or `null`
   */
  const setSelectedNodeTypeFromArray = useCallback(
    (newIndex: number | null): void => {
      if (newIndex === null) return

      setSelectedNodeType(NODE_TYPE_OPTIONS[newIndex].value)
      if (NODE_TYPE_OPTIONS[newIndex].value === 'double-size-info') {
        setSelectedNodeStyle(prevStyle => {
          if (prevStyle === null) return null

          return {
            ...prevStyle,
            width: '28em'
          }
        })
      } else {
        setSelectedNodeStyle(prevStyle => {
          if (prevStyle === null) return null

          return {
            ...prevStyle,
            width: '14em'
          }
        })
      }
    },
    [NODE_TYPE_OPTIONS]
  )

  /**
   * Focuses the view towards a given node
   * @param node The node to focus on
   */
  const focusNode = useCallback(
    (node: Node): void => {
      // Bias the zoom-to-node towards the top of the node so that the header section of taller elements is still visible
      const x = (node.__rf?.position.x as number) + node.__rf?.width / 2
      const y = (node.__rf?.position.y as number) + node.__rf?.height / 4

      setCenter(x, y, FOCUS_NODE_ZOOM)
    },
    [setCenter, FOCUS_NODE_ZOOM]
  )

  /**
   * Called when the save button is pressed
   */
  const savePathwayButton = async (): Promise<void> => {
    // eslint-disable-next-line
    const [res, err] = await wrapPromise(
      updatePathway(
        currentPathway.ID,
        currentPathway.Name,
        currentPathway.Description,
        currentElements,
        currentPathway.catID,
        userToken
      )
    )
    if (err !== null) {
      toast.error('Pathway could not be saved!')
      return
    }
    toast.success('Pathway Saved!')
  }
  /**
   * Called when publish button is pressed
   */
  const publishPathwayButton = async (): Promise<void> => {
    // eslint-disable-next-line
    const [res, err] = await wrapPromise(
      publishPathway(
        currentPathway.ID,
        currentPathway.Name,
        currentPathway.Description,
        currentElements,
        currentPathway.catID,
        userToken
      )
    )
    if (err !== null) {
      toast.error('Pathway could not be published')
      return
    }
    setPublished(true)
    toast.success(currentPathway.Name.concat(' has been published'))
  }

  /**
   * Called when the export button is pressed
   */
  const exportPathwayButton = async (): Promise<void> => {
    // Define JSON object to be exported
    const pathwayExport: ExportedPathway = {
      ID: currentPathway.ID,
      Name: currentPathway.Name,
      Description: currentPathway.Description,
      Data: currentElements,
      catID: currentPathway.catID
    }

    // Get current date and convert to a string
    const today = new Date()
    const dateString = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(
      2,
      '0'
    )}-${today.getFullYear()}`

    // Data to be written to a file
    const fileData = JSON.stringify(pathwayExport)
    const fileName = `${currentPathway.ID}-${currentPathway.Name}-${dateString}.json`
    // Generate file and download it
    const blob = new Blob([fileData], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = fileName
    link.href = url
    link.click()

    toast.success(`Pathway exported to ${fileName}`)
  }

  /**
   * Called when the rename pathway button is pressed
   */
  const renamePathwayButton = async (): Promise<void> => {
    // eslint-disable-next-line
    const [res, err] = await wrapPromise(
      updatePathway(
        currentPathway.ID,
        newPathwayName === '' ? currentPathway.Name : newPathwayName,
        currentPathway.Description,
        currentElements,
        currentPathway.catID,
        userToken
      )
    )
    if (err !== null) {
      toast.error('Pathway could not be renamed')
      return
    }
    // Update details after a successful DB change
    currentPathway.Name = newPathwayName === '' ? currentPathway.Name : newPathwayName
    toast.success('Pathway renamed as '.concat(currentPathway.Name))
  }

  /**
   * Called when the save as button is pressed
   */
  const savePathwayAsButton = async (): Promise<void> => {
    const [res, err] = await wrapPromise(
      savePathwayAs(
        newPathwayName === '' ? currentPathway.Name : newPathwayName,
        currentPathway.Description,
        currentElements,
        currentPathway.catID,
        userToken
      )
    )
    if (err !== null) {
      toast.error('Save as operation failed')
      return
    }
    // Update details after a successful DB change
    currentPathway.Name = newPathwayName === '' ? currentPathway.Name : newPathwayName
    currentPathway.ID = res.pathID
    toast.success('Pathway saved as '.concat(currentPathway.Name))
  }

  /**
   * Called when the set category button is pressed
   */
  const setCategoryButton = async (): Promise<void> => {
    const [res, err] = await wrapPromise(getCategoryID(catname))
    if (err !== null) {
      toast.error('Category could not be found')
      return
    }
    // eslint-disable-next-line
    const [res2, err2] = await wrapPromise(
      updatePathway(currentPathway.ID, currentPathway.Name, currentPathway.Description, currentElements, res.catID, userToken)
    )
    if (err2 !== null) {
      toast.error('Pathway category could not be changed')
      return
    }
    // Update details after a successful DB change
    currentPathway.catID = res.catID
    currentCategory.Name = catname
    toast.success(`Pathway has been set to category: ${catname}`)
  }

  /**
   * Called when the delete button is pressed
   */
  const confirmDelete = (): void => {
    confirmAlert({
      title: 'Delete Pathway',
      message: 'Are you sure want to permanently delete this pathway?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await deletePathway(currentPathway.ID, userToken)
            window.location.href = '/admin/pathway-overview'
          }
        },
        {
          label: 'No',
          onClick: () => toast.info('Pathway not deleted')
        }
      ]
    })
  }

  /**
   * Called when the unpublish button is pressed
   */
  const confirmUnpublish = (): void => {
    confirmAlert({
      title: 'Unpublish Pathway',
      message: 'Are you sure want to unpublish this pathway?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const [_, err] = await wrapPromise(unpublishPathway(currentPathway.ID, userToken))
            if (err !== null) {
              toast.error('Failed to unpublish pathway')
            } else {
              setPublished(false)
              toast.success('Pathway successfully unpublished')
            }
          }
        },
        {
          label: 'No',
          onClick: () => toast.info('Pathway not unpublished')
        }
      ]
    })
  }

  return (
    <Container>
      <Card.Container style={{ marginBottom: '0.5rem' }}>
        <Heading style={{ marginBottom: '0' }} color="deepskyblue" bold>
          Editing Panel
        </Heading>
      </Card.Container>
      <ScrollableContainer>
        <Card.Container style={{ pointerEvents: 'auto' }}>
          <Card.Header>
            <Text bold>Edit Title</Text>
          </Card.Header>

          <Card.Content>
            <Input.Text
              value={selectedNodeTitle}
              placeholder="Select a node first to edit its title"
              onChange={e => setSelectedNodeTitle(e.target.value)}
            />
          </Card.Content>

          <Card.Divider />

          <Card.Header>
            <Text bold>Edit Content</Text>
          </Card.Header>

          <Card.Content>
            <Input.RichText
              value={selectedNodeContent}
              onChange={setSelectedNodeContent}
              placeholder="Select a node first to edit its content"
            />
          </Card.Content>

          <Card.Divider />

          <Card.Header>
            <Text bold>Outline</Text>
          </Card.Header>

          <Card.Content>
            <BorderStyleContainer>
              <Input.Dropdown
                placeholder="Border style"
                options={BORDER_STYLE_OPTIONS}
                selectedOption={selectedBorderStyle}
                setSelectedOption={setSelectedBorderStyle}
              />

              <Input.Dropdown
                placeholder="Border color"
                options={BORDER_COLOR_OPTIONS}
                selectedOption={selectedBorderColor}
                setSelectedOption={setSelectedBorderColor}
              />
            </BorderStyleContainer>
          </Card.Content>

          <Card.Divider />

          <Card.Header>
            <Text bold>Node Type</Text>
          </Card.Header>

          <Card.Content>
            <Input.Dropdown
              placeholder="Select a node first to edit its type"
              options={NODE_TYPE_OPTIONS}
              selectedOption={selectedNodeInArray}
              setSelectedOption={setSelectedNodeTypeFromArray}
            />
          </Card.Content>
        </Card.Container>

        <Spacer size="md" />

        <Card.Container style={{ pointerEvents: 'auto', display: 'grid' }}>
          <Card.Header>
            <Text style={{ flex: 1 }} bold>
              Save Options
            </Text>
          </Card.Header>

          <Card.Content>
            <button onClick={savePathwayButton}>Save</button>

            <button onClick={publishPathwayButton}>
              {!isPublished && 'Publish'}
              {isPublished && 'Update Published Version'}
            </button>

            <button onClick={exportPathwayButton}>Export To File</button>
            <Card.Divider />

            <Input.Text
              style={{ flex: 1 }}
              value={newPathwayName}
              placeholder="Enter new pathway name..."
              onChange={e => setPathwayName(e.target.value)}
            />
            <button
              onClick={async () => {
                await renamePathwayButton()
                navigate(`/admin/creation-tool/${currentPathway.ID}`) // Reload the page to reflect changes
              }}
            >
              Rename
            </button>
            <button
              onClick={async () => {
                await savePathwayAsButton()
                navigate(`/admin/creation-tool/${currentPathway.ID}`) // Reload the page to reflect changes
              }}
            >
              Save As
            </button>

            <Card.Divider />

            <select style={{ flex: 1 }} value={catname} onChange={e => setCatName(e.target.value)}>
              {stateCategories.map(category => {
                return <option value={category.catName}> {category.catName} </option>
              })}
            </select>
            <button
              onClick={async () => {
                await setCategoryButton()
                navigate(`/admin/creation-tool/${currentPathway.ID}`) // Reload the page to reflect changes
              }}
            >
              Set Category
            </button>

            <Card.Divider />

            {isPublished && (
              <button onClick={confirmUnpublish} style={{ color: 'red' }}>
                Unpublish Pathway
              </button>
            )}

            <button onClick={confirmDelete} style={{ color: 'red' }}>
              Delete Pathway
            </button>

            <ToastContainer position="bottom-center" pauseOnHover={false} />
          </Card.Content>
        </Card.Container>

        <Spacer size="md" />

        <Card.Container style={{ pointerEvents: 'auto', overflow: 'auto!important', marginBottom: '10vh' }}>
          <Card.Header>
            <Text style={{ flex: 1 }} bold>
              List of Nodes
            </Text>

            <Input.Text style={{ flex: 1 }} value={query} placeholder="Search Node" onChange={e => setQuery(e.target.value)} />
          </Card.Header>

          <Card.Content>
            {nodes.map((node, index) => (
              <>
                <NodesListItem type={node.type as any} title={node.data.title} onClick={_ => focusNode(node)} />

                {/* Do not render spacer for the last item */}
                {index !== nodes.length - 1 && <Spacer size="xs" />}
              </>
            ))}
          </Card.Content>
        </Card.Container>
      </ScrollableContainer>
    </Container>
  )
}

/**
 * Computes the index of the selected border style from the array of options
 * @param nodeStyle The node style from where to extract the information
 * @returns The index of the selected border style or `null` if not match is found
 */
function getSelectedBorderStyle(nodeStyle: CustomNodeStyle | null): number | null {
  if (nodeStyle === null) return null

  const index = BORDER_STYLE_OPTIONS.map(({ value }) => value).indexOf(nodeStyle.borderStyle)
  return index !== -1 ? index : null
}

/**
 * Computes the index of the selected border color from the array of options
 * @param nodeStyle The node style from where to extract the information
 * @returns The index of the selected border color or `null` if not match is found
 */
function getSelectedBorderColor(nodeStyle: CustomNodeStyle | null): number | null {
  if (nodeStyle === null) return null

  const index = BORDER_COLOR_OPTIONS.map(({ value }) => value).indexOf(nodeStyle.borderColor)
  return index !== -1 ? index : null
}

/**
 * Computes the index of the selected node type from the array of options
 * @param nodeType The string containing the node style
 * @returns The index of the selected node type or `null` if no match is found
 */
function getSelectedNodeType(nodeType: string | ''): number | null {
  if (nodeType === '') return null

  const index = NODE_TYPE_OPTIONS.map(({ value }) => value).indexOf(nodeType)
  return index !== -1 ? index : null
}

interface IProps {
  currentElements: Elements<CustomNodeData>
  currentPathway: IPathway
  currentCategory: ICategory
  selectedNodeTitle: string
  selectedNodeContent: string
  selectedNodeStyle: CustomNodeStyle | null
  selectedNodeType: string
  setSelectedNodeTitle: (value: React.SetStateAction<string>) => void
  setSelectedNodeContent: (value: React.SetStateAction<string>) => void
  setSelectedNodeStyle: (value: React.SetStateAction<CustomNodeStyle | null>) => void
  setSelectedNodeType: (value: React.SetStateAction<string>) => void
  isPublished: boolean
  setPublished: (value: React.SetStateAction<boolean>) => void
}
