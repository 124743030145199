/**
 * Defines the About page of the website
 */
import React from 'react'
import { Heading, SmallText, Text, Title } from '../../components/typography'
import styled from 'styled-components'

const BodyContainer = styled.div`
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  border: 0.1rem solid deepskyblue;
  height: 100%;

  @media (orientation: portrait) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

export const About: React.FC = () => {
  return (
    <div>
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem', textAlign: 'center' }}
      >
        <Title>About</Title>
        <SmallText color="grey">Information About the Pathways' Creation and Use</SmallText>
      </div>
      <BodyContainer>
        <Heading style={{ fontSize: '1.3rem' }}>Pathway Updates</Heading>
        <Text style={{ marginBottom: '0.75rem' }}>
          Pathways are complex to create and because laws and regulations change, they need to be updated. Our updates may not
          appear immediately, and we refer you to our disclaimer.
        </Text>
        <Heading style={{ fontSize: '1.3rem' }}>Feedback</Heading>
        <Text style={{ marginBottom: '0.75rem' }}>
          Any complex decision support system may contain errors in content and design, which may only be identified by users.
          They are your pathways, and you have a unique opportunity to ensure their accuracy and ease of use. The feedback forms
          are an essential tool to identify and rectify problems, and ensure the system is accurate, up to date, and easy to
          use. The pathways are as good as you can make them.
        </Text>
        <Heading style={{ fontSize: '1.3rem' }}>User Obligations, Ownership Rights</Heading>
        <Text style={{ marginBottom: '0.75rem' }}>
          The information provided in this website is provided in good faith and is free of charge to parents of children with
          special educational needs or disabilities.
          <br></br>
          <br></br>
          These pathways are free to be used by parents and volunteers. However, changes and edits are prohibited without
          permission of the copyright holder.
          <br></br>
          <br></br>
          If you want to use any part of these pathways for any commercial or profit making reason, you must seek our
          permission.
        </Text>
        <Heading style={{ fontSize: '1.3rem' }}>Disclamer</Heading>
        <Text>
          The content is provided for general information about the law and legal practice in England only. You should obtain
          professional or specialist advice before taking, or refraining from, any action on the basis of the content of our
          site. Although we make reasonable efforts to update the information on our site, we make no guarantee that the content
          on our site is accurate, complete, or up to date.
        </Text>
      </BodyContainer>
    </div>
  )
}
