/**
 * A base component to which all typography based comoponents extend from.
 */
import styled from 'styled-components'

export const TypographyBase = styled.span<IProps>`
  display: block;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: ${props => (props.bold !== undefined ? 600 : 400)};
  text-align: ${props => (props.centered !== undefined ? 'center' : 'match-parent')};
  color: ${props => (props.color !== undefined ? props.color : '#212121')};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
/**
 * Defines properties of typography components
 */
interface IProps {
  bold?: boolean // determines if bold
  centered?: boolean // determines if text-align is centre
  color?: string // determines color in HEX format
}
