/**
 * Define header for a card
 */
import React from 'react'
import styled from 'styled-components'

import { Spacer } from '../../components/spacer'

const Container = styled.div`
  justify-content: space-between;
  align-items: center;

  display: flex;
`

export const CardHeader: React.FC = ({ children, ...props }) => (
  <>
    <Container {...props}>{children}</Container>
    <Spacer />
  </>
)
