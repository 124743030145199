/**
 * Define collapsible div
 */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IProps {
  open?: boolean
  title: string
}

const Container = styled.div`
  display: flex;
`

const ButtonContainer = styled.span`
  float: right;
  margin: auto 0;
  padding-left: 5px;
`

const CategoryHeading = styled.h3`
  color: #00bfff;
  font-weight: 600 !important;
`

export const Collapsible: React.FC<IProps> = ({ open, children, title }) => {
  const [isOpen, setIsOpen] = useState(open)

  /**
   * Opens and closes div
   */
  const handleFilterOpening = (): void => {
    setIsOpen(prev => !(prev ?? true))
  }

  /**
   * Sets is open to show or hide div
   */
  useEffect(() => {
    setIsOpen(prev => !(prev ?? true))
  }, [])

  return (
    <div>
      <Container>
        <CategoryHeading>{title}</CategoryHeading>
        <ButtonContainer>
          <button type="button" className="btn" onClick={handleFilterOpening} style={{ boxShadow: 'none' }}>
            {!(isOpen ?? false) ? '+' : '-'}
          </button>
        </ButtonContainer>
      </Container>

      <div>
        <div className="showTable">{(isOpen ?? false) && <div>{children}</div>}</div>
      </div>
    </div>
  )
}
