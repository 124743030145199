/**
 * Defines the Logout page of the website
 */
import React, { useEffect } from 'react'
import { SubTitle } from '../../components/typography'

export const Logout: React.FC = () => {
  useEffect(() => {
    localStorage.removeItem('userToken')
  }, [])
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
        <SubTitle>Logout Complete</SubTitle>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
        <button
          onClick={() => {
            window.location.href = '/' // Used instead of navigate() to force the navbar to be re-rendered
          }}
        >
          Return to Home
        </button>
      </div>
    </div>
  )
}
