import React, { useState, useEffect } from 'react'
import { SmallText, Title } from '../../components/typography'
import { wrapPromise } from '../../utils/promise'
import { getLastUpdated, listPathways } from '../../api/pathways/public'
import { listCategories } from '../../api/categories'
// needed to use the groupby function
import _ from 'lodash'
// needed to be able to close pathways for each category section
import { Collapsible } from '../../components/collapsible'
// you need these styles for React Flow to work properly
import styled from 'styled-components'

const PathTable = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 8px;
  }

  table td {
    padding: 12px 15px;
    background-color: rgb(219, 219, 219);
    text-align: center;
  }

  table tr:hover td {
    background-color: rgb(255, 255, 255);
  }

  table th {
    padding-top: 12px;
    padding-bottom: 15px;
    text-align: center;
    background-color: rgb(0, 0, 0);
    color: white;
  }

  li:hover {
    text-decoration: underline;
    color: black;
    font-weight: bold;
    cursor: pointer;
  }
`

const BodyContainer = styled.div`
  margin-left: 2rem;
  margin-top: 1rem;
`
const LastUpdatedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem;
`

export const PathwayCategories: React.FC = () => {
  const userToken = localStorage.getItem('userToken') ?? ''
  const pathwaysList = [{ pathID: 0, pathName: '', pathDescription: '', catID: 0 }]
  const categoriesList = [{ catID: 0, catName: '' }]
  const [stateOptionsPath, setStateValues] = useState(pathwaysList)
  const [stateOptionsCat, setStateValues2] = useState(categoriesList)
  const [lastUpdated, setLastUpdated] = useState<string>('')

  /**
   * Uses the id parameter to find and return the category name as a string
   * @param id type number that is used to find the associated category name with that id
   * @return type string, category name if found, else wrong
   */

  const catName = (id: number): string => {
    let i = 0
    while (i !== stateOptionsCat.length) {
      // compares the id associated with the element in the array with the id submitted
      if (stateOptionsCat[i].catID === id) {
        return stateOptionsCat[i].catName
      }
      i++
    }
    return 'wrong'
  }
  // fetches and sets using hooks the complete list of public pathways
  useEffect(() => {
    void (async () => {
      const [res, err] = await wrapPromise(listPathways(userToken))
      // if an error is found return without setting values to array
      if (err !== null) return

      setStateValues(res)
    })()
  }, [])
  // fetches and sets using hooks the complete list of categories
  useEffect(() => {
    void (async () => {
      // api function that returns the list of categories from the categories table, or an error message if none found
      const [res, err] = await wrapPromise(listCategories())
      // if an error is found return without setting values to array
      if (err !== null) return

      setStateValues2(stateOptionsCat => [...stateOptionsCat, ...res])
    })()
  }, [])
  // fetches and sets using hooks the date the pathways were last updated
  useEffect(() => {
    void (async () => {
      const [res, err] = await wrapPromise(getLastUpdated())
      // if an error occurs return
      if (err !== null) return

      const date = new Date(res) // Convert the string to a date object
      setLastUpdated(`Pathways were last updated on: ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`) // Month starts at 0 so +1
    })()
  })
  // sets an array const that uses the groupby import to sort the pathways in the array based on the category id
  const result = _.groupBy(stateOptionsPath, path => path.catID)
  return (
    <div id="categories">
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem', textAlign: 'center' }}
      >
        <Title>Finding Pathways</Title>
        <SmallText color="grey">A list of pathways separated by category</SmallText>
      </div>
      <BodyContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            marginBottom: '1.5rem'
          }}
        >
          <b style={{ fontSize: '20px', paddingTop: '20px', paddingBottom: '5px' }}>Identifying the category</b>
          <SmallText color="black">
            The most common problems for which help may be needed will fall into one or more of the following categories:
          </SmallText>
          <ul>
            <li>
              <SmallText>EHC needs assessment</SmallText>
            </li>
            <li>
              <SmallText>EHC plan</SmallText>
            </li>
            <li>
              <SmallText>School / further education</SmallText>
            </li>
            <li>
              <SmallText>Behaviours</SmallText>
            </li>
            <li>
              <SmallText>Healthcare</SmallText>
            </li>
            <li>
              <SmallText>Social care</SmallText>
            </li>
            <li>
              <SmallText>Appeals, complaints</SmallText>
            </li>
          </ul>
          <SmallText color="black">Identify the first appropriate category into which your problem belongs.</SmallText>
          <b style={{ fontSize: '20px', paddingTop: '20px', paddingBottom: '5px' }}>Identify the pathway</b>
          <SmallText color="black">
            Review the specific pathways within this category and select the most relevant one.
          </SmallText>
          <b style={{ fontSize: '20px', paddingTop: '20px', paddingBottom: '5px' }}>More than one category</b>
          <SmallText color="black">Help may be sought for more than one problem, related to more than one category. </SmallText>
          <SmallText color="black">After selecting the first relevant category and pathway, other categories and</SmallText>
          <SmallText color="black">pathways can be identified if necessary.</SmallText>
        </div>
      </BodyContainer>
      <div style={{ marginLeft: '1.5rem' }} className="container">
        {Object.entries(result).map(([id, path]) => {
          // maps each element in the array to a list element which is returned to the display
          return (
            <div className="dep" key={id}>
              <Collapsible open={false} title={catName(Number(id))}>
                <PathTable>
                  <ul style={{ listStyle: 'none', paddingLeft: '1rem' }}>
                    {path.map(pathway => {
                      return (
                        <li
                          style={{ marginBottom: '1.3rem' }}
                          key={pathway.pathID}
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            window.location.href = `/pathway-display/${pathway.pathID}`
                          }}
                        >
                          {pathway.pathName}
                        </li>
                      )
                    })}
                  </ul>
                </PathTable>
              </Collapsible>
            </div>
          )
        })}
      </div>
      <LastUpdatedContainer>
        <SmallText color="grey">{lastUpdated}</SmallText>
      </LastUpdatedContainer>
    </div>
  )
}
