/**
 * Exports all parts that can be used to create a modern looking card
 */
import { CardContainer } from './CardContainer'
import { CardContent } from './CardContent'
import { CardDivider } from './CardDivider'
import { CardHeader } from './CardHeader'

export const Card = {
  Container: CardContainer,
  Content: CardContent,
  Divider: CardDivider,
  Header: CardHeader
}
