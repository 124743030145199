import React, { useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { getUser, updateUser, changePassword, newUser, listUser, setAdminUser, deleteUser } from '../../api/users'
import { Button } from '../../components/button'
import { Card } from '../../components/card'
import { Input } from '../../components/input'
import { Spacer } from '../../components/spacer'
import { Text, SmallText } from '../../components/typography'
import { confirmAlert } from 'react-confirm-alert'
import { ToastContainer, toast } from 'react-toastify'
import { wrapPromise } from '../../utils/promise'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'

const Container = styled.div`
  align-items: center;
  justify-content: center;

  display: flex;

  height: 100vh;

  background-color: #f5f5f5;
`

const ActionsContainer = styled(Card.Container)`
  align-items: center;
  justify-content: flex-end;

  display: flex;
`

const PathTable = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 8px;
  }

  table td {
    padding: 12px 15px;
    background-color: rgb(219, 219, 219);
    text-align: center;
  }

  table tr:hover td {
    background-color: rgb(255, 255, 255);
  }

  table th {
    padding-top: 12px;
    padding-bottom: 15px;
    text-align: center;
    background-color: rgb(0, 0, 0);
    color: white;
  }
`

export const UserPortal: React.FC = () => {
  const userToken = localStorage.getItem('userToken') ?? ''
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [newName, setNewName] = useState('')
  const [newUsername, setNewUsername] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newConfirmPassword, setNewConfirmPassword] = useState('')
  const [admin, setAdmin] = useState(false)
  const [isAdminChecked, setIsAdminChecked] = useState(false)
  const emptyUserList = [{ Username: '', Name: '', Admin: false }]
  const [userList, setUserList] = useState<IUserData[]>(emptyUserList)

  useEffect(() => {
    void (async (): Promise<void> => {
      // eslint-disable-next-line
      const [res, err] = await wrapPromise(getUser(userToken))
      if (err !== null) {
        toast.error('Current user details not found')
      }
      setUsername(res.username)
      setName(res.name)
      setAdmin(res.admin)
    })()
  }, [])

  useEffect(() => {
    void (async (): Promise<void> => {
      const [res] = await wrapPromise(listUser(userToken))
      setUserList(res)
    })()
  }, [])

  /**
   * Called when the button is pressed to update any user detail
   */
  const onUpdateUser = async (): Promise<void> => {
    const checkPassword = prompt('Enter password')
    const getPassword = checkPassword != null ? checkPassword : ''
    const [_res, err] = await wrapPromise(updateUser(username, name, getPassword, userToken))
    if (err !== null) {
      toast.error('Error occurred whilst trying to update details')
      return
    }
    toast.success('Changes made successfully')
    navigate(0)
  }

  const onSetAdmin = async (tableUsername: string, tableAdmin: boolean): Promise<void> => {
    const [_res, err] = await wrapPromise(setAdminUser(tableUsername, tableAdmin, userToken))
    if (err !== null) {
      toast.error('Error occurred whilst trying to change '.concat(tableUsername).concat("'s Admin privileges"))
      return
    }
    toast.success('Changes made successfully')
    navigate(0)
  }

  const onDeleteUser = (tableUsername: string): void => {
    confirmAlert({
      title: 'Delete '.concat(tableUsername),
      message: 'Are you sure want to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await confirmDeleteUser(tableUsername)
            navigate(0)
          }
        },
        {
          label: 'No',
          onClick: () => toast.info(tableUsername.concat(' not Deleted'))
        }
      ]
    })
  }

  const confirmDeleteUser = async (tableUsername: string): Promise<void> => {
    const [_res, err] = await wrapPromise(deleteUser(tableUsername, userToken))
    if (err !== null) {
      toast.error('Error occurred whilst trying delete '.concat(tableUsername))
      return
    }
    toast.success('Changes made successfully')
  }

  const onUpdatePassword = async (): Promise<void> => {
    const checkPassword = prompt('Enter current password')
    const getPassword = checkPassword != null ? checkPassword : ''
    const [_res, err] = await wrapPromise(changePassword(getPassword, password, userToken))
    if (err !== null) {
      toast.error('Error occurred whilst trying to update details')
      return
    }
    toast.success('Changes made successfully')
  }

  const onNewUser = async (): Promise<void> => {
    const [_res, err] = await wrapPromise(newUser(newUsername, newName, isAdminChecked, newPassword, userToken))
    if (err !== null) {
      toast.error('Error occurred whilst trying to create User')
      return
    }
    toast.success('New User '.concat(newName).concat(' has been made'))
    navigate(0)
  }

  const handleOnChange = (): void => {
    setIsAdminChecked(!isAdminChecked)
  }

  /**
   * Pops the history stack and navigates to the previously visited page
   */
  const back = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      navigate(-1)
    },
    [navigate]
  )

  return (
    <Container>
      <Card.Container style={{ width: '33%', minWidth: '20rem', maxWidth: '30rem' }}>
        <form>
          <Card.Header>
            <Text bold>Change Name</Text>
          </Card.Header>

          <Card.Content>
            <Input.Text value={name} placeholder="Enter a new name" onChange={e => setName(e.target.value)} required />
          </Card.Content>

          <Card.Header>
            <Text bold>Change Username</Text>
          </Card.Header>

          <Card.Content>
            <Input.Text
              value={username}
              placeholder="Enter a new username"
              onChange={e => setUsername(e.target.value)}
              required
            />
          </Card.Content>

          <Spacer size="lg" />

          <ActionsContainer>
            <Button.Text type="button" onClick={back}>
              Go back
            </Button.Text>
            <Spacer size="lg" />
            <Button.Text type="button" onClick={onUpdateUser} disabled={name.length === 0 || username.length === 0} solid>
              Update
            </Button.Text>
          </ActionsContainer>
        </form>
      </Card.Container>

      <Card.Container style={{ width: '33%', minWidth: '20rem', maxWidth: '30rem' }}>
        <form>
          <Card.Header>
            <Text bold>Change Password</Text>
          </Card.Header>

          <Card.Content>
            <Input.Text
              type="password"
              value={password}
              placeholder="Enter a new password"
              onChange={e => setPassword(e.target.value)}
              required
            />

            <Input.Text
              type="password"
              value={confirmPassword}
              placeholder="Confirm new password"
              onChange={e => setConfirmPassword(e.target.value)}
              required
            />
            {newPassword !== newConfirmPassword && <SmallText color="grey">Passwords do not match!</SmallText>}
          </Card.Content>

          <Spacer size="lg" />

          <ActionsContainer>
            <Button.Text type="button" onClick={back}>
              Go back
            </Button.Text>
            <Spacer size="lg" />
            <Button.Text
              type="button"
              onClick={onUpdatePassword}
              disabled={password.length === 0 || confirmPassword.length === 0}
              solid
            >
              Update
            </Button.Text>
          </ActionsContainer>
        </form>
      </Card.Container>

      {admin && (
        <>
          <Card.Container style={{ width: '33%', minWidth: '20rem', maxWidth: '30rem' }}>
            <form>
              <Card.Header>
                <Text bold>Add New User</Text>
              </Card.Header>

              <Card.Content>
                <Input.Text
                  value={newName}
                  placeholder="Enter a new name"
                  onChange={e => setNewName(e.target.value)}
                  required
                />
                <Spacer size="xs" />
                <Input.Text
                  value={newUsername}
                  placeholder="Enter a new username"
                  onChange={e => setNewUsername(e.target.value)}
                  required
                />
                <Spacer size="xs" />
                <Input.Text
                  type="password"
                  value={newPassword}
                  placeholder="Enter a new password"
                  onChange={e => setNewPassword(e.target.value)}
                  required
                />
                <Spacer size="xs" />
                <Input.Text
                  type="password"
                  value={newConfirmPassword}
                  placeholder="Confirm new password"
                  onChange={e => setNewConfirmPassword(e.target.value)}
                  required
                />
                <label>
                  <input type="checkbox" value="Admin" checked={isAdminChecked} onChange={handleOnChange} />
                  Admin
                </label>
                {newPassword !== newConfirmPassword && <SmallText color="grey">Passwords do not match!</SmallText>}
              </Card.Content>

              <Spacer size="lg" />

              <ActionsContainer>
                <Button.Text type="button" onClick={back}>
                  Go back
                </Button.Text>
                <Spacer size="lg" />
                <Button.Text
                  type="button"
                  onClick={onNewUser}
                  disabled={newName.length === 0 || newUsername.length === 0 || newPassword !== newConfirmPassword}
                  solid
                >
                  Add User
                </Button.Text>
              </ActionsContainer>
            </form>
          </Card.Container>
          <PathTable>
            <table>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Name</th>
                  <th>Admin</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {userList.map((user, i) => {
                  return (
                    <tr key={i}>
                      <td>{user.Username}</td>
                      <td>{user.Name}</td>
                      <td>
                        <Button.Icon
                          onClick={async () => {
                            await onSetAdmin(user.Username, !user.Admin)
                          }}
                        >
                          {user.Admin ? <AiOutlineCheckCircle /> : <AiOutlineCloseCircle />}
                        </Button.Icon>
                      </td>
                      <td>
                        <button
                          onClick={async () => {
                            await onDeleteUser(user.Username)
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </PathTable>
        </>
      )}
      <ToastContainer position="bottom-center" pauseOnHover={false} />
    </Container>
  )
}

interface IUserData {
  Username: string
  Name: string
  Admin: boolean
}
