/**
 * Create default styling of a button
 */
import styled from 'styled-components'

export const DefaultButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`
