/**
 * Defines the typography for small text
 */
import styled from 'styled-components'

import { TypographyBase } from './TypographyBase'

export const SmallText = styled(TypographyBase)`
  font-size: 1.1rem;
`
