/**
 * Decision Node inherits from CustomNodeBase. Is used to display information.
 */
import React from 'react'
import { Handle, Node, Position } from 'react-flow-renderer/nocss'
// you need these styles for React Flow to work properly
import 'react-flow-renderer/dist/style.css'
// load the default theme
import 'react-flow-renderer/dist/theme-default.css'

import { CustomNodeBase } from '../CustomNodeBase'
import { HTMLRenderer } from '../HTMLRenderer'
import '../CustomReactFlow.css'

export const InformationNode: React.FC<IProps> = props => {
  if (props.data === undefined) {
    return null // return if data left underfined
  }

  /**
   * Define layout and structure of node, including handles
   */
  const { content, title, style } = props.data
  return (
    <CustomNodeBase title={title} nodeStyle={style} {...props}>
      <Handle type="target" id="left-handle" position={Position.Left} style={{ background: '#555' }} isConnectable />
      <Handle type="target" id="top-handle" position={Position.Top} style={{ background: '#555' }} isConnectable />
      <Handle type="source" id="right-handle" position={Position.Right} style={{ background: '#555' }} isConnectable />
      <Handle type="source" id="bottom-handle" position={Position.Bottom} style={{ background: '#555' }} isConnectable />

      <HTMLRenderer content={content} />
    </CustomNodeBase>
  )
}

interface IProps extends Node<CustomNodeData> {}
