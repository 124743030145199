import { apiInstance } from '../axios'

export const createCategory = async (name: string, accessToken: string): Promise<ICategory> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(
        '/categories/new',
        {
          Name: name
        },
        { headers: { authorization: `Bearer ${accessToken}` } }
      )
      .then(res => resolve(res.data))
      .catch(reject)
  })
}

export const getCategoryID = async (name: string): Promise<ICategory> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post<ICategory>('/categories/get_catID', { Name: name })
      .then(res => {
        resolve(res.data)
        return res.data
      })
      .catch(reject)
  })
}

export const listCategories = async (): Promise<ICategory[]> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .get<ICategory[]>('/categories/list')
      .then(res => resolve(res.data))
      .catch(reject)
  })
}

export const readCategory = async (id: number): Promise<ICategory> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .get<ICategory>(`/categories/get/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err.response))
  })
}

export const deleteCategory = async (id: number, accessToken: string): Promise<ICategory> => {
  return await new Promise((resolve, reject) => {
    apiInstance
      .post(`/categories/delete/${id}`, {}, { headers: { authorization: `Bearer ${accessToken}` } })
      .then(res => resolve(res.data))
      .catch(reject)
  })
}

interface ICategory extends IErrorResponse {
  catID: number
  catName: string
}

interface IErrorResponse {
  error?: string
}
