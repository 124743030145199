/**
 * Defines the text area input underneath the toolbar
 */
import styled from 'styled-components'

import { Defaults } from '../../components/defaults'

const _TextAreaInput = styled(Defaults.TextArea)`
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.5rem 0.375rem;

  background-color: #f5f5f5;

  resize: none;
`

export const TextAreaInput: React.FC<React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({ ...props }) => (
  <_TextAreaInput {...props} />
)
