/**
 * Defines modal to delete a category
 */
import React, { useEffect, useState } from 'react'
// import the relevant api function to interact with the backend
import { deleteCategory, getCategoryID, listCategories } from '../../../api/categories'
import { Title } from '../../../components/typography'
// you need these styles for React Flow to work properly
import styled from 'styled-components'
// imports a close button style
import { FaWindowClose } from 'react-icons/fa'
import { wrapPromise } from '../../../utils/promise'
import { toast } from 'react-toastify'

const Button = styled.button`
  background-color: black;
  height: 40px;
  width: 300px;
  color: #fff;
  border-radius: 10px;
`
const Container = styled.div`
  position: fixed;
  background: rgba(158, 158, 158, 0.7);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
`
const Display = styled.div`
  position: center;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 15px;
  padding: 20px;
`
const CloseButton = styled.button`
  margin-top: 1px;
  margin-right: 2px;
  position: absolute;
  top: 3;
  right: 3;
  background-color: black;
  color: white;
  border-radius: 20px;
`

export const CategoryDeletePopup: React.FC = () => {
  const [catname, setCatName] = useState('')
  const userToken = localStorage.getItem('userToken') ?? ''
  // sets the initial state of the array
  const categoriesList = [{ catID: 0, catName: 'one' }]
  const [stateCategories, setStateCategories] = useState(categoriesList)
  // function deletes a category using the category id
  const DelCategory = async (): Promise<void> => {
    // fetches the category id associated the parameter name
    const [res, err] = await wrapPromise(getCategoryID(catname))
    // returns and displays a message if an error is found
    if (err !== null) {
      toast.error('Category Could Not Be Deleted')
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [res2, err2] = await wrapPromise(deleteCategory(res.catID, userToken))
    // returns and displays a message if an error is found
    if (err2 !== null) {
      toast.error('Category Could Not Be Deleted')
      return
    }
    void closePage()
  }
  // refreshes page to show the updated tables
  const closePage = (): void => {
    window.location.reload()
  }
  useEffect(() => {
    void (async () => {
      // fetches the list of all categories
      const [res, err] = await wrapPromise(listCategories())
      // returns if an error is returned
      if (err !== null) return

      setStateCategories(res)
      setCatName(res[0].catName)
    })()
  }, [])

  return (
    <Container>
      <Display>
        <CloseButton onClick={closePage}> {FaWindowClose} x </CloseButton>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Title color="black">Delete Category</Title>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <text style={{ padding: '10px', color: 'grey' }}>Select Category: </text>
          <select style={{ width: '300px', height: '45px' }} value={catname} onChange={e => setCatName(e.target.value)}>
            {stateCategories.map(category => {
              return <option value={category.catName}> {category.catName} </option>
            })}
          </select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Button id="dangerButtonInput" onClick={DelCategory}>
            Delete
          </Button>
        </div>
      </Display>
    </Container>
  )
}
