/**
 * Defines the Login page of the website
 */
import React, { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { loginUser, getUser } from '../../api/users'
import { Button } from '../../components/button'
import { Card } from '../../components/card'
import { Input } from '../../components/input'
import { Spacer } from '../../components/spacer'
import { Text } from '../../components/typography'
import { UserContext } from '../../context/user-context'
import { wrapPromise } from '../../utils/promise'

const Container = styled.div`
  align-items: center;
  justify-content: center;

  display: flex;

  height: 100vh;

  background-color: #f5f5f5;
`

const ActionsContainer = styled(Card.Container)`
  align-items: center;
  justify-content: flex-end;

  display: flex;
`

export const Login: React.FC = () => {
  const { setUser } = useContext(UserContext) ?? { setUser: () => {} }
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  /**
   * Pops the history stack and navigates to the previously visited page
   */
  const back = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      navigate(-1)
    },
    [navigate]
  )

  /**
   * Authenticates the user
   */
  const login = useCallback(
    async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault()

      // Authenticates user
      const [loginRes, loginErr] = await wrapPromise(loginUser(username, password))
      if (loginErr !== null) {
        if (loginErr.status === 401) {
          alert('Invalid username or password')
          return
        }

        alert('Server error')
        return
      }

      // Get user details
      const [getRes, getErr] = await wrapPromise(getUser(loginRes.access_token))
      if (getErr !== null) {
        alert('Server error')
        return
      }

      // Update user stored in context
      setUser(_ => ({
        admin: getRes.admin,
        accessToken: loginRes.access_token,
        name: getRes.name,
        username: getRes.username
      }))
      localStorage.setItem('userToken', loginRes.access_token)
      // Redirect to dashboard on successful login
      window.location.href = '/admin/dashboard' // Used instead of navigate() to force the navbar to be re-rendered
    },
    [username, password, setUser, navigate]
  )

  return (
    <Container>
      <Card.Container style={{ width: '33%', minWidth: '20rem', maxWidth: '30rem' }}>
        <form onSubmit={login}>
          <Card.Header>
            <Text bold>Username</Text>
          </Card.Header>

          <Card.Content>
            <Input.Text
              value={username}
              placeholder="Enter your username"
              onChange={e => setUsername(e.target.value)}
              required
            />
          </Card.Content>

          <Spacer size="lg" />

          <Card.Header>
            <Text bold>Password</Text>
          </Card.Header>

          <Card.Content>
            <Input.Text
              type="password"
              value={password}
              placeholder="Enter your password"
              onChange={e => setPassword(e.target.value)}
              required
            />
          </Card.Content>

          <Spacer />

          <ActionsContainer>
            <Button.Text type="button" onClick={back}>
              Go back
            </Button.Text>
            <Spacer size="lg" />
            <Button.Text type="submit" disabled={username.length === 0 || password.length === 0} solid>
              Login
            </Button.Text>
          </ActionsContainer>
        </form>
      </Card.Container>
    </Container>
  )
}
