/**
 * Create a default styling of a TextArea
 */
import styled from 'styled-components'

export const DefaultTextArea = styled.textarea`
  outline: inherit;
  margin: 0;
  border: none;
  box-sizing: border-box;
  padding: 0;

  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  text-align: match-parent;
  color: ${props => (props.color !== undefined ? props.color : '#212121')};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
