/**
 * Defines modal to add new pathway
 */
import React, { useState, useEffect } from 'react'
import { createPathway } from '../../../api/pathways/private'
import { Title, Text } from '../../../components/typography'
// you need these styles for React Flow to work properly
import styled from 'styled-components'
// imports a close button style
import { FaWindowClose } from 'react-icons/fa'
// functions that interact with the backend api
import { getCategoryID, listCategories } from '../../../api/categories'
import { wrapPromise } from '../../../utils/promise'
import { toast } from 'react-toastify'

const Button = styled.button`
  background-color: black;
  height: 40px;
  width: 300px;
  color: #fff;
  border-radius: 10px;
`
const CloseButton = styled.button`
  margin-top: 1px;
  margin-right: 2px;
  position: absolute;
  top: 3;
  right: 3;
  background-color: black;
  color: white;
  border-radius: 20px;
`
const Container = styled.div`
  position: fixed;
  background: rgba(158, 158, 158, 0.7);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
`
const Display = styled.div`
  position: center;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 15px;
  padding: 20px;
`

export const PathwayAddPopup: React.FC = () => {
  const userToken = localStorage.getItem('userToken') ?? ''
  const [pathname, setPathName] = useState('')
  const [pathdes, setPathDes] = useState('')
  const [pathcat, setPathCat] = useState('')
  const categoriesList = [{ catID: 0, catName: 'one' }] // sets an initial state
  const [stateCategories, setStateValues3] = useState(categoriesList)
  // uses the imported function to add a pathway to the database
  const addPath = async (): Promise<void> => {
    // gets the category id using the category name
    const [res, err] = await wrapPromise(getCategoryID(pathcat))
    if (err !== null) return

    await wrapPromise(createPathway(pathname, pathdes, Number(res.catID), userToken))
    void closePage()
  }
  // refreshes the page to update the tables data
  const closePage = (): void => {
    window.location.reload()
  }
  useEffect(() => {
    void (async () => {
      // reads a list of the categories
      const [res, err] = await wrapPromise(listCategories())
      // returns and displays a message if an error is found
      if (err !== null) {
        toast.error('No categories could be found')
        return
      }
      setStateValues3(res)
      setPathCat(res[0].catName)
    })()
  }, [])

  return (
    <Container>
      <Display>
        <CloseButton onClick={closePage}> {FaWindowClose} x </CloseButton>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Title color="black">Create Pathway</Title>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Text style={{ padding: '10px', color: 'grey' }}>Enter Pathway Name: </Text>
          <input
            style={{ borderRadius: '8px', width: '300px', height: '30px' }}
            type="text"
            value={pathname}
            onChange={e => setPathName(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Text style={{ padding: '10px', color: 'grey' }}>Enter Description: </Text>
          <input
            style={{ borderRadius: '8px', width: '300px', height: '30px' }}
            type="text"
            value={pathdes}
            onChange={e => setPathDes(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Text style={{ padding: '10px', color: 'grey' }}>Select Category: </Text>
          <select style={{ width: '300px', height: '45px' }} value={pathcat} onChange={e => setPathCat(e.target.value)}>
            {stateCategories.map(category => {
              return <option value={category.catName}> {category.catName} </option>
            })}
          </select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Button onClick={addPath}>Enter</Button>
        </div>
      </Display>
    </Container>
  )
}
