/**
 * Defines a spacer component which spaces out elements when placed between two elements
 */
import styled from 'styled-components'

export const Spacer = styled.div<IProps>`
  width: ${p => computeSize(p.size)}rem;
  height: ${p => computeSize(p.size)}rem;
`
Spacer.defaultProps = {
  size: 'sm'
}

/**
 * Computes the size in relative units of the spacer element given the requested size
 * @param size The requested size
 * @returns The computed size in relative units
 */
function computeSize(size?: Size): number {
  switch (size) {
    case 'xs':
      return 0.375
    case 'sm':
      return 0.5
    case 'md':
      return 0.75
    case 'lg':
      return 1
    default:
      return 0.5
  }
}

// define different size options
type Size = 'xs' | 'sm' | 'md' | 'lg'

interface IProps {
  size?: Size // size is optional, defaults to small
}
