import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { readPathway } from '../../api/pathways/public'
import { readCategory } from '../../api/categories'
import { PathwayDisplay } from '../../views/pathway-display'
import { wrapPromise } from '../../utils/promise'
import { useParams } from 'react-router-dom'

const Container = styled.div`
  width: 100%;
  height: 90vh;
`

export const PathwayDisplaySetup: React.FC = () => {
  const { id } = useParams()
  const PATHWAY_ID = Number(id)

  const [pathway, setPathway] = useState<IPathway | null>(null)
  const [category, setCategory] = useState<ICategory | null>(null)

  useEffect(() => {
    // Fetch pathway on component mount
    void (async () => {
      const [res, err] = await wrapPromise(readPathway(PATHWAY_ID))
      if (err !== null) return

      setPathway(_ => ({
        ID: res.pathID,
        Name: res.pathName,
        Description: res.pathDescription,
        Data: res.pathData,
        catID: res.catID
      }))

      const [resCat, errCat] = await wrapPromise(readCategory(res.catID))
      if (errCat !== null) return

      setCategory(_ => ({
        ID: resCat.catID,
        Name: resCat.catName
      }))
    })()
  }, [])

  if (pathway === null || category === null) return null

  return (
    <Container>
      <PathwayDisplay pathway={pathway} category={category} />
    </Container>
  )
}
