/**
 * Defines the Dashboard page of the website
 */
import React, { useEffect, useState } from 'react'
import { Title, SubTitle, SmallText } from '../../components/typography'
import { Link } from 'react-router-dom'
// styled icons for the dashboard
import { FaComment, FaUsers, FaBook, FaCloudsmith } from 'react-icons/fa'
import styled from 'styled-components'
import { TypographyBase } from '../../components/typography/TypographyBase'
import { wrapPromise } from '../../utils/promise'
import { getUser } from '../../api/users'

const FeedbackButton = styled.button`
  background: #a7a7a7;
  width: 250px;
  height: 250px;
  border-top-right-radius: 300px;
  float: right;
  border: 5px solid #fff;
  font-size: medium;

  &:hover {
    background-color: hsl(209, 52%, 31%);
    color: white;
    position: relative;
    bottom: 15px;
    left: 15px;
  }

  &:hover #fbText {
    color: white;
  }
`
const UsersButton = styled.button`
  background: #a7a7a7;
  width: 250px;
  height: 250px;
  border-bottom-right-radius: 300px;
  float: right;
  border: 5px solid #fff;

  &:hover {
    background-color: hsl(209, 52%, 31%);
    color: white;
    position: relative;
    top: 15px;
    left: 15px;
  }

  &:hover #UsersText {
    color: white;
  }
`
const ManualButton = styled.button`
  background: #a7a7a7;
  width: 250px;
  height: 250px;
  border-top-left-radius: 300px;
  border: 5px solid #fff;

  &:hover {
    background-color: hsl(209, 52%, 31%);
    color: white;
    position: relative;
    bottom: 15px;
    right: 15px;
  }

  &:hover #ManualText {
    color: white;
  }
`
const PathwaysButton = styled.button`
  background: #a7a7a7;
  width: 250px;
  height: 250px;
  border-bottom-left-radius: 300px;
  float: left;
  border: 5px solid #fff;

  &:hover {
    background-color: hsl(209, 52%, 31%);
    color: white;
    position: relative;
    top: 15px;
    right: 15px;
  }

  &:hover #pwText {
    color: white;
  }
`
const CenterButton = styled.button`
  background: #ffffff;
  width: 100px;
  height: 100px;

  border-radius: 100px;
  border: 20px solid #fff;

  position: relative;
  bottom: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`
const Container = styled.div`
  background-color: white;
  width: 500px;
  height: 500px;
  padding: 50px;
  position: center;
  margin: auto;
`
const FeedbackText = styled(TypographyBase)`
  font-size: 0.875rem;
  padding-top: 3%;
  padding-right: 7%;
  font-style: oblique;
`
const UsersText = styled(TypographyBase)`
  font-size: 0.875rem;
  padding-right: 11%;
  padding-bottom: 8%;
  font-style: oblique;
`
const ManualText = styled(TypographyBase)`
  font-size: 0.875rem;
  padding-top: 4%;
  padding-left: 11%;
  font-style: oblique;
`
const PathwayText = styled(TypographyBase)`
  font-size: 0.875rem;
  padding-left: 12%;
  padding-bottom: 8%;
  font-style: oblique;
`

export const DashBoard: React.FC = () => {
  const userToken = localStorage.getItem('userToken') ?? ''
  const [stateOptionsName, setStateNameValue] = useState(String)
  useEffect(() => {
    void (async () => {
      const [res, err] = await wrapPromise(getUser(userToken))
      if (err !== null) return
      setStateNameValue(res.name)
    })()
  }, [])
  return (
    <div id="home">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem', color: 'black' }}>
        <Title color="black">Admin Dashboard</Title>
        <SmallText color="grey">Pathways to support understanding of the law relating to Special Educational Needs</SmallText>
      </div>

      <Container>
        <FeedbackButton onClick={() => window.open('https://www.office.com/launch/forms', '_blank')}>
          <FaComment fontSize="50px" />
          <FeedbackText>Feedback</FeedbackText>
        </FeedbackButton>
        <ManualButton
          onClick={() => window.open('https://www.sendecisions.org.uk/resources/user-manual-c360ca3a-49ea.pdf', '_blank')}
        >
          <FaBook fontSize="50px" />
          <ManualText>Manual</ManualText>
        </ManualButton>
        <Link to="/admin/user-portal">
          <UsersButton>
            <FaUsers fontSize="50px" />
            <UsersText>Users</UsersText>
          </UsersButton>
        </Link>
        <Link to="/admin/pathway-overview">
          <PathwaysButton>
            <FaCloudsmith fontSize="50px" />
            <PathwayText>Pathways</PathwayText>
          </PathwaysButton>
        </Link>
        <CenterButton className="Center"></CenterButton>
      </Container>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'black' }}>
        <SubTitle color="deepskyblue">Signed In As: {stateOptionsName}</SubTitle>
      </div>
    </div>
  )
}
