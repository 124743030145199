import React, { useCallback, useEffect, useState } from 'react'
import { useStoreState, useZoomPanHelper } from 'react-flow-renderer/nocss'
// you need these styles for React Flow to work properly
import 'react-flow-renderer/dist/style.css'
// load the default theme
import 'react-flow-renderer/dist/theme-default.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-confirm-alert/src/react-confirm-alert.css'

export const OnLoad: React.FC = () => {
  const { fitView, setCenter } = useZoomPanHelper()
  const nodes = useStoreState(state => state.nodes)
  const [isMobile, setIsMobile] = useState(false)
  const [FOCUS_NODE_ZOOM, setFocus] = useState(1.5)
  let x = -1
  let y = -1

  const handleResize = (): void => {
    if (window.innerWidth < window.innerHeight) {
      setIsMobile(true)
      setFocus(0.4)
    } else {
      setIsMobile(false)
      setFocus(0.8)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  /**
   * Focuses the view towards the top of the pathway
   */
  const panToTop = useCallback((): void => {
    handleResize()
    if (nodes.length > 0) {
      const topNodeY = Math.min.apply(
        // find the highest y position any node has
        Math,
        nodes.map(function (o) {
          return o.__rf?.position.y
        })
      )
      const topNode = nodes.find(function (o) {
        // find the node with highest position
        return o.__rf?.position.y === topNodeY
      })
      if (topNode !== undefined) {
        // if the node exists
        const emitTopNode = Array.from(nodes) // make copy of nodes
        emitTopNode.splice(nodes.indexOf(topNode), 1) // remove the highest node
        const secondTopNodeY = Math.min.apply(
          // find the second highest y position
          Math,
          emitTopNode.map(function (o) {
            return o.__rf?.position.y
          })
        )
        const secondTopNode = emitTopNode.find(function (o) {
          // find the node with the second highest y position
          return o.__rf?.position.y === secondTopNodeY
        })
        if (secondTopNode !== undefined) {
          // if now both the first and second node exist then...
          const topNodeX = (topNode.__rf?.position.x as number) + topNode.__rf?.width / 2 // get the first node x position
          const secondTopNodeX = (secondTopNode.__rf?.position.x as number) + secondTopNode.__rf?.width / 2 // get the second node x position
          if (x === -1) {
            x = (topNodeX + secondTopNodeX) / 2
            // find average of the two x positions so we can pan roughly into the middle of the pathway
            y = topNode.__rf?.position.y as number
            // get the highest y position in the pathway
          }
          setCenter(x + 130, y, FOCUS_NODE_ZOOM) // use gathered coords to pan to the top of the pathway
          if (isMobile) {
            // focus to the top left node if viewing on mobile
            setCenter(x + 130, y, FOCUS_NODE_ZOOM) // use gathered coords to pan to the top of the pathway
          }
        } else {
          fitView()
        }
      }
    }
  }, [setCenter, FOCUS_NODE_ZOOM, isMobile])

  /**
   * calls the focus node function, only re running if the focus node's dependencies change
   */
  useEffect(() => {
    panToTop()
  }, [panToTop])

  return <div></div>
}
