/**
 * Defines the RichTextInput box
 */
import React, { useCallback, useEffect } from 'react'
import { AiFillRead } from 'react-icons/ai'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ReactTooltip from 'react-tooltip'

// Custom inline formatter to create tooltips using react-tooltip
const InlineBlot = Quill.import('blots/inline')
class TooltipBlot extends InlineBlot {
  static blotName = 'insert-tooltip'
  static tagName = 'tooltip'

  static create(value?: string): HTMLElement {
    const node: HTMLElement = super.create()

    // Populate data field used by react-tooltip to show the tooltip
    if (value !== undefined) {
      node.setAttribute('data-tip', value)
    }

    return node
  }

  static formats(node: HTMLElement): any {
    return {
      'data-tip': node.getAttribute('data-tip')
    }
  }
}
Quill.register('formats/insert-tooltip', TooltipBlot)

const MODULES = {
  toolbar: {
    container: '#quill-toolbar',
    handlers: {
      'insert-tooltip': function (value: boolean) {
        if (value) {
          const tip = prompt('Enter Tooltip Text:')
          ;(this as any).quill.format('insert-tooltip', tip)
        } else {
          ;(this as any).quill.format('insert-tooltip', false)
        }
      }
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  }
}

// define options for the formatter toolbar
const FORMATS = ['bold', 'italic', 'underline', 'list', 'link', 'color', 'align', 'insert-tooltip']

const QuillToolbar: React.FC = () => (
  <div id="quill-toolbar">
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />

      <select className="ql-color">
        <option value="black"></option>
        <option value="#b528d4"></option>
        <option value="#0000FF"></option>
        <option value="#40e0d0"></option>
        <option value="#f6546a"></option>
        <option value="deepskyblue"></option>
        <option value="#008000"></option>
        <option value="#3c67bc"></option>
        <option value="#ff0000"></option>
        <option value="#652a96"></option>
        <option value="#feb144"></option>
        <option value="#c4564f"></option>
        <option value="#ffff00"></option>
        <option value="#87ca46"></option>
      </select>

      <select className="ql-align">
        <option value=""></option>
        <option value="center"></option>
        <option value="right"></option>
      </select>
    </span>

    <span className="ql-formats">
      <button className="ql-list" value="bullet" />
      <button className="ql-list" value="ordered" />
    </span>

    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-insert-tooltip">
        <AiFillRead size="1.125rem" />
      </button>
    </span>
  </div>
)

export const RichTextInput: React.FC<IProps> = ({ value, onChange, ...props }) => {
  // Avoid rendering an empty newline
  const _onChange = useCallback((value: string): void => (value !== '<p><br></p>' ? onChange(value) : onChange('')), [onChange])

  // Magic fuckery to make tooltips work (???)
  // prettier-ignore
  useEffect(() => { ReactTooltip.rebuild() })

  return (
    <>
      <QuillToolbar />
      <ReactQuill theme="snow" value={value} onChange={_onChange} modules={MODULES} formats={FORMATS} {...props} />
    </>
  )
}

interface IProps extends ReactQuill.ReactQuillProps {
  value: string
  onChange: (value: string) => void
}
