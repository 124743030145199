/**
 * Defines the Home page of the website
 */
import React, { useState, useEffect } from 'react'
import TutorialVideo from '../../components/tutorial-video/DemoVideo.mp4'
import TutorialImage from '../../components/tutorial-video/demoImage.jpg'
import LogoText from '../../components/logo/SENLogoText.png'
import { Title, Text, SmallText, Heading } from '../../components/typography'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const Overview = styled.div`
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  @media (orientation: landscape) {
    flex-direction: row;
  }

  @media (orientation: portrait) {
    flex-direction: column;
  }
`
const Introduction = styled.div`
  padding: 2rem;
  float: left;
  width: 100%;
  ul li {
    list-style-position: outside;
    margin-left: 1em;
  }
  @media (orientation: portrait) {
    padding: 0em;
  }
`
const IntroductionText = styled.div`
  @media (orientation: portrait) {
    padding: 1em;
  }
`

const MainHeader = styled.div`
  @media (orientation: portrait) {
    text-align: center;
  }
`

const Tutorial = styled.div`
  padding: 2rem;
  float: right;
  display: block;
  margin: auto;
`

const Content = styled.div`
  margin-top: 0.5rem;
  text-align: left;
  background-color: black;
  color: white;
  padding: 2rem;
`

const LinkContainer = styled.div`
  span {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`

export const Home: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false)

  const handleResize = (): void => {
    if (window.innerWidth < window.innerHeight) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    handleResize()
  }, [isMobile])

  return (
    <div id="Home">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem', color: 'black' }}>
        <img src={LogoText} className="homeLogo" alt="Logo with Text" />
        <MainHeader>
          <Title color="black">Special Educational Needs Pathways</Title>
          <SmallText color="grey">Pathways to support understanding of the law relating to Special Educational Needs</SmallText>
        </MainHeader>
      </div>
      <Overview>
        <Introduction>
          <IntroductionText>
            <Heading id="intro" color="white" bold>
              Introduction
            </Heading>
            <Text color="white">
              These pathways are now maintained by the Law Clinic at the University of Liverpool. We do this with the support of
              our students, who study the law relating to children with Special Educational Needs as part of their degree. They
              are intended as a support tool. We hope that you find them useful.
            </Text>
            <br></br>
            <Text color="white">
              These pathways deal with problems related to assessing special educational needs and obtaining educational, health
              and social care provision.
            </Text>
            <Text color="white">They enable users to:</Text>

            <ul id="list">
              <li>
                <SmallText color="white">Make informed legal decisions</SmallText>
              </li>
              <li>
                <SmallText color="white">Identify and challenge illegal decisions</SmallText>
              </li>
              <li>
                <SmallText color="white">Follow clear lawful procedures for:</SmallText>
              </li>
              <ul>
                <li>
                  <SmallText color="white">Assessments</SmallText>
                </li>
                <li>
                  <SmallText color="white">Appeals</SmallText>
                </li>
                <li>
                  <SmallText color="white">School Problems</SmallText>
                </li>
                <li>
                  <SmallText color="white">Complaints</SmallText>
                </li>
              </ul>
            </ul>
          </IntroductionText>
        </Introduction>

        <Tutorial>
          <div style={{ marginBottom: '1.5em' }}>
            <ReactPlayer
              url={TutorialVideo}
              light={TutorialImage}
              width={isMobile ? '320px' : '640px'}
              height={isMobile ? '180px' : '360px'}
              controls
            />
          </div>
        </Tutorial>
      </Overview>
      <Content>
        <Heading color="white" bold>
          Navigating the Website
        </Heading>
        <LinkContainer>
          <Text color="white">
            <a href="/pathway-categories">Finding the Pathways</a> - The starting point for going from problem to pathway
          </Text>
          <Text color="white">
            <a href="/supporting-documents">Supporting Documents</a> - These are relevant documents that may be useful to view
            alongside the pathways
          </Text>
          <Text color="white">
            <a href="/about">About</a> - Information about the pathways, copyright, and the accuracy of the content of this
            website
          </Text>
          <Text color="white">
            <a href="/acknowledgements">Acknowledgements</a> - Acknowledging the people who helped with the pathways and website
          </Text>
          <Text color="white">
            <a href="/contact">Contact Us</a> - Contact information for issues or suggestions relating to the website
          </Text>
        </LinkContainer>
      </Content>
    </div>
  )
}
