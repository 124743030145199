/**
 * Define divider for a card
 */
import React from 'react'
import styled from 'styled-components'

import { Spacer } from '../../components/spacer'

const Divider = styled.div`
  opacity: 0.5;

  margin: 0 -1.25rem;
  margin-bottom: -1px;
  height: 1px;

  background-color: #bdbdbd;
`

export const CardDivider: React.FC = () => (
  <>
    <Spacer />
    <Divider />
    <Spacer size="md" />
  </>
)
