/**
 * Defines modal to create new category
 */
import React, { useState } from 'react'
// import the relevant api function to interact with the backend
import { createCategory } from '../../../api/categories'
import { Title } from '../../../components/typography'
// you need these styles for React Flow to work properly
import styled from 'styled-components'
// imports a close button style
import { FaWindowClose } from 'react-icons/fa'
import { wrapPromise } from '../../../utils/promise'
import { toast } from 'react-toastify'

const Button = styled.button`
  background-color: black;
  height: 40px;
  width: 300px;
  color: #fff;
  border-radius: 10px;
`
const Container = styled.div`
  position: fixed;
  background: rgba(158, 158, 158, 0.7);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
`
const CloseButton = styled.button`
  margin-top: 1px;
  margin-right: 2px;
  position: absolute;
  top: 3;
  right: 3;
  background-color: black;
  color: white;
  border-radius: 20px;
`
const Display = styled.div`
  position: center;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 15px;
  padding: 20px;
`

export const CategoryAddPopup: React.FC = () => {
  const [state, setState] = useState('')
  const userToken = localStorage.getItem('userToken') ?? ''
  // adds a category to the database
  const addCategory = async (): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [res, err] = await wrapPromise(createCategory(state, userToken))
    if (err !== null) {
      toast.error('Category Could Not Be Deleted')
      return
    }
    void closePage()
  }
  // refreshes to show user the updated table data
  const closePage = (): void => {
    window.location.reload()
  }
  return (
    <Container>
      <Display>
        <CloseButton onClick={closePage}> {FaWindowClose} x </CloseButton>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Title color="black">Add Category</Title>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <text style={{ padding: '10px', color: 'grey' }}>Enter Category Name: </text>
          <input
            style={{ borderRadius: '8px', width: '300px', height: '30px' }}
            type="text"
            value={state}
            onChange={e => setState(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Button onClick={addCategory}>Enter</Button>
        </div>
      </Display>
    </Container>
  )
}
