/**
 * Defines a decision edge. These a custom edges with labels 'Yes' or 'No' attached to them depending on their source handle.
 * Can only be created by the yes-handle and no-handle of a Decision Node.
 */
import React, { useEffect, useRef, useState } from 'react'
import { EdgeSmoothStepProps, getMarkerEnd, getSmoothStepPath, Rect } from 'react-flow-renderer/nocss'
// you need these styles for React Flow to work properly
import 'react-flow-renderer/dist/style.css'
// load the default theme
import 'react-flow-renderer/dist/theme-default.css'

export const DecisionEdge: React.FC<IProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId
}) => {
  const edgeRef = useRef<SVGTextElement>(null)
  const [edgeTextBbox, setEdgeTextBbox] = useState<Rect>({ x: 0, y: 0, width: 0, height: 0 })

  const edgePath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition })
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)
  /**
   * x and y coordinates are adjusted based on label as Yes is expected to be on top of the edge
   * and No is expected to be on the left of an edge
   */
  const [x, y] = getEdgeStartWithOffset(sourceX, sourceY, targetX, targetY, 16, data?.text)
  const yPosition = data?.text === 'Yes' ? edgeTextBbox.height / 2 - 15 : edgeTextBbox.height / 2
  const xPosition = data?.text === 'No' ? x - edgeTextBbox.width / 2 - 15 : x - edgeTextBbox.width / 2
  useEffect(() => {
    if (edgeRef.current !== null) {
      const textBbox = edgeRef.current.getBBox()

      setEdgeTextBbox({
        x: textBbox.x,
        y: textBbox.y,
        width: textBbox.width,
        height: textBbox.height
      })
    }
  }, [data?.text])

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
      <g transform={`translate(${xPosition} ${y - edgeTextBbox.height / 2})`} className="react-flow__edge-textwrapper">
        <text className="react-flow__edge-text" y={yPosition} dy="0.3em" ref={edgeRef}>
          {data?.text}
        </text>
      </g>
    </>
  )
}

function getEdgeStartWithOffset(
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number,
  offset: number,
  labelText?: string
): [number, number] {
  // create custom offsets for Yes and No positioning
  const startWithOffsetX = sourceX > targetX ? sourceX - offset : sourceX + offset
  const startWithOffsetY = sourceY > targetY ? sourceY - offset : sourceY + offset
  // use correct x and y coords based on whether an offset is needed in respective dimension
  const offsetX = labelText === 'No' ? sourceX : startWithOffsetX
  const offsetY = labelText === 'Yes' ? sourceY : startWithOffsetY
  return [offsetX, offsetY]
}

interface IProps extends EdgeSmoothStepProps<{ text: string }> {}
