/**
 * Defines the Contact Us page of the website
 */
import React from 'react'
import { Text, Title } from '../../components/typography'
import styled from 'styled-components'

const BodyContainer = styled.div`
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  border: 0.1rem solid deepskyblue;
  height: 100%;
  span {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  @media (orientation: portrait) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

export const ContactUs: React.FC = () => {
  return (
    <div>
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem', textAlign: 'center' }}
      >
        <Title>Contact Us</Title>
      </div>
      <BodyContainer>
        <Text style={{ fontSize: '1.3rem' }} bold>
          Users can contact us on{' '}
          <a href="mailto:info@sendecisions.org.uk?subject=SEN Decisions Website Enquiry">info@sendecisions.org.uk </a>
        </Text>
        <Text>
          Suggestions for improvements, information about problems or corrections, are welcome. We will try to respond to your
          queries.
        </Text>
        <Text>
          Alternatively, feedback for a particular pathway can be provided{' '}
          <a href="https://forms.office.com/r/aASAxNc2Tg" target="_blank">
            here
          </a>
        </Text>
      </BodyContainer>
    </div>
  )
}
