import { Elements, isNode } from 'react-flow-renderer/nocss'
import DOMPurify from 'dompurify'

// Enforce HTML only
const SANITIZE_OPTIONS = { USE_PROFILES: { html: true } }

/**
 * Sanitize the content of pathway's nodes
 * @param pathData The data to sanitize
 */
export function sanitizePathData(pathData: Elements<CustomNodeData>): Elements<CustomNodeData> {
  return pathData.map(el => {
    // Skip edges
    if (!isNode(el)) return el

    // Sanitize node content
    if (el.data !== undefined) {
      const { content } = el.data
      el.data = { ...el.data, content: DOMPurify.sanitize(content, SANITIZE_OPTIONS) }
    }

    return el
  })
}

export interface ICreatePathwayResponse extends IErrorResponse {
  message: string
  pathID: number
}

export interface IReadPathwayResponse extends IErrorResponse {
  pathID: number
  pathName: string
  pathDescription: string
  pathData: Elements<CustomNodeData>
  catID: number
}

export interface IReadPathwayAttributes extends IErrorResponse {
  pathID: number
  pathName: string
  pathDescription: string
  catID: number
}

export interface IUpdatePathwayResponse extends IErrorResponse {
  message: string
  pathID: number
}

export interface IPublishPathwayResponse extends IErrorResponse {
  message: string
  pathID: number
}

export interface IErrorResponse {
  error?: string
}
