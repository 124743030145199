/**
 * Renders a Pathway onto a canvas and allows functionality relating to the manipulation of a pathway. Read or Edit mode can be specified.
 */
import React from 'react'
import ReactFlow, { Background, Connection, Controls, Edge, Elements, Node, OnLoadFunc } from 'react-flow-renderer/nocss'
import styled from 'styled-components'

// you need these styles for React Flow to work properly
import 'react-flow-renderer/dist/style.css'

import { DecisionEdge } from './components/custom-edges'
import { DecisionNode, InformationNode } from './components/custom-nodes'

const Container = styled.div`
  flex: 16;

  background-color: #fafafa;
`
// Define expected node types
const NODE_TYPES: INodeTypes = {
  'information-node': InformationNode,
  'decision-node': DecisionNode,
  'double-size-info': InformationNode
}
// Define expected edge types
const EDGE_TYPES: IEdgeTypes = {
  'decision-edge': DecisionEdge
}

/**
 * Creates a pathway canvas component
 * @param readOnly boolean representing whether Pathway is in read only mode
 * @param elements Array of Elements to be rendered
 * @param containerRef Reference to parent container
 * @param onLoad on load function to execute when Pathway is loaded
 * @returns
 */
export const PathwaysCanvas: React.FC<IProps> = ({ readOnly, elements, containerRef, onLoad, ...props }) => {
  // Returned component if read only, used within PathwayDisplay
  if (readOnly) {
    return (
      <Container ref={containerRef}>
        <ReactFlow
          elements={elements}
          snapToGrid={true}
          snapGrid={[16, 1]}
          nodeTypes={NODE_TYPES}
          edgeTypes={EDGE_TYPES}
          nodesDraggable={false} // nodes can not change from initial position
          nodesConnectable={false} // new edges can not be made
          onLoad={onLoad}
          panOnScroll={true}
          defaultZoom={0.5}
          minZoom={0.2}
          defaultPosition={[700, 100]}
        >
          <Controls showInteractive={false} />
        </ReactFlow>
      </Container>
    )
  }

  /**
   * Set of functions returned as properties associated with IEditableGraphProps interface. Used to manipulate and transform pathway. Only used within the creation tool.
   */
  const { onConnect, onElementClick, onElementsRemove, onDragOver, onDrop, onNodeDrag } = props as IEditableGraphProps
  return (
    <Container ref={containerRef}>
      <ReactFlow
        elements={elements}
        snapToGrid={true}
        snapGrid={[16, 1]}
        onElementsRemove={onElementsRemove}
        onElementClick={onElementClick}
        onConnect={onConnect}
        nodeTypes={NODE_TYPES}
        edgeTypes={EDGE_TYPES}
        onLoad={onLoad}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onNodeDrag={onNodeDrag}
        panOnScroll={true}
        defaultZoom={0.5}
        minZoom={0.1}
        defaultPosition={[700, 100]}
        multiSelectionKeyCode={'Control'}
      >
        <Background color="#A4A4A4" gap={16} size={1.0} />
        <Controls />
      </ReactFlow>
    </Container>
  )
}

type IProps = IEditableGraphProps | IReadOnlyGraphProps

/**
 * Interface representing expected properties regardless of readOnly mode value
 */
interface ISharedGraphProps {
  elements: Elements
  containerRef?: React.RefObject<HTMLDivElement>
  onLoad?: OnLoadFunc
}

/**
 * Interface extending shared properties, used for editable pathway canvas i.e when readOnly === false
 */
interface IEditableGraphProps extends ISharedGraphProps {
  readOnly: false
  onConnect: (params: Edge | Connection) => void
  onElementsRemove: (elementsToRemove: Elements) => void
  onElementClick: (evt: React.MouseEvent, element: Node | Edge) => void
  onDragOver: React.DragEventHandler
  onDrop: React.DragEventHandler
  onNodeDrag: (evt: React.MouseEvent, node: Node) => void
}

/**
 * If interface is set to IReadOnlyGraphProps, readOnly is automatically set to true
 */
interface IReadOnlyGraphProps extends ISharedGraphProps {
  readOnly: true
}

// Expected Node types
type INodeTypes = {
  [nodeType in CustomNodeType]: React.ReactNode
}

// Expected Edge types
type IEdgeTypes = {
  [edgeType in CustomEdgeType]: React.ReactNode
}
