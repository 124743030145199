/**
 * Defines the typography for a Title
 */
import styled from 'styled-components'

import { TypographyBase } from './TypographyBase'

export const Title = styled(TypographyBase)`
  font-size: 2.375rem;
  font-weight: ${props => (props.bold !== undefined ? 900 : 700)};
  color: ${props => (props.color !== undefined ? props.color : '#00bfff')};
`
