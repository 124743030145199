/**
 * Defines styling for a normal TextInput
 */
import styled from 'styled-components'

import { Defaults } from '../../components/defaults'

const _TextInput = styled(Defaults.Input)`
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.5rem 0.375rem;

  background-color: #f5f5f5;
`

export const TextInput: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({ ...props }) => (
  <_TextInput type="text" {...props} />
)
