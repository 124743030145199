/**
 * Defines a button which contains an Icon
 */
import styled from 'styled-components'

import { Defaults } from '../../components/defaults'

/**
 * Defines styling of button
 */
export const IconButton = styled(Defaults.Button)<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 175ms background-color ease-in-out;

  width: ${({ size }) => size};
  height: ${({ size }) => size};

  background-color: rgba(0, 0, 0, 0);

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.1);
  }
`
/**
 * Default size defined
 */
IconButton.defaultProps = {
  size: '2rem'
}

/**
 * Button properties
 */
interface IProps {
  size?: string
}
